<template>
	<v-text-field
					v-bind="$attrs"
					v-on="$listeners"
					color="accent"
					:name="name"
					:data-tabordername="tabOrderName || name"
					@keydown="$emit('keydown', $event)"
					@keydown.native="$checkForTabOrderEvent($event)"
					@keyup="$emit('keyup', $event)"
					ref="field"
					autocomplete="off"
	></v-text-field>
</template>

<script>
export default {
	name: 'SimpleTextInput',

	methods: {
		focus() {
			this.$refs.field.focus();
		},

		blur() {
			this.$refs.field.blur();
		},
	},

	props: ['name', 'tabOrderName'],
}
</script>

<style scoped>

</style>
