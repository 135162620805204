<template>
	<v-slide-x-transition hide-on-leave>
		<div>
			<v-menu
							ref="menu"
							:close-on-content-click="false"
							v-model="menu"
							:nudge-right="40"
							:return-value.sync="date"
							transition="scale-transition"
							offset-y
							min-width="290px"
							:open-on-click="openOnClick"
			>
				<template v-slot:activator="{on}">
					<v-text-field
									v-on="on"
									v-bind="$attrs"
									slot="activator"
									:autofocus="autofocus"
									:label="noLabel ? '' : label ? label : model.constructor.propertyMap[name].label"
									append-icon="mdi-calendar"
									mask="####-##-##"
									:data-name="name"
									:data-tabordername="tabOrderName || name"
									return-masked-value
									v-model="model[name]"
									@click:append="menu = !menu"
									ref="field"
									:rules="model.$rules[name]"
									@keyup.enter="$emit('keyup', $event)"
									@keydown.native="$checkForTabOrderEvent($event)"
									@change="onChange"
									autocomplete="off"
									@blur="onBlur"
									@focus="onFocus"
									color="accent"
									@keydown.down.native.stop.prevent="onKeyDownDown"
									@keydown.up.native.stop.prevent="onKeyDownUp"
									persistent-placeholder
					></v-text-field>
				</template>

				<v-date-picker v-model="date" no-title scrollable :first-day-of-week="1" :min="minDate" :max="maxDate" locale="sv" :type="type" @change="onSelectDate(date)" show-week :locale-first-day-of-year="4">
					<v-spacer></v-spacer>
					<v-btn text color="secondary" @click="menu = false">{{$t('Cancel')}}</v-btn>
				</v-date-picker>
			</v-menu>
		</div>
	</v-slide-x-transition>
</template>

<script>
import {get}  from 'lodash'
import moment from 'moment';

export default {
	name:  'DatePicker',
	props: {
		openOnClick: {
			type:    Boolean,
			default: false,
		},
		type: {
			type:         String,
			defaultValue: 'date',
		},
		autofocus: {
			type:    Boolean,
			default: false,
		},
		model: {
			required: true,
		},
		name: {
			required: true,
		},
		tabOrderName: {
			type:    String,
			default: '',
		},
		noLabel: {
			type:    Boolean,
			default: false,
		},
		label: {
			type:    String,
			default: '',
		},
		minDate: {
			type:    String,
			default: '',
		},
		maxDate: {
			type:    String,
			default: '',
		},
		openOnFocus: {
			type:    Boolean,
			default: false,
		},
	},

	data: () => ({
		menu:         false,
		date:         '',
		valueOnFocus: null,
	}),

	mounted() {
		this.prevent18991230();

		if(this.model && this.modelValue) {
			this.setDateFromModelValue();
		} else {
			this.date = this.dateWhenEmpty;
		}
	},

	watch: {
		modelValue() {
			this.prevent18991230();

			this.$nextTick(() => {
				if((this.model[this.name] || '').length > 10) {
					this.model[this.name] = this.model[this.name].slice(0, 10);
				}
				this.setDateFromModelValue();
			});
		},

		'SharedViewFilters.dateFilter': 'setDateFromModelValue',
	},

	computed: {
		modelValue() {
			return this.model[this.name];
		},

		dateWhenEmpty() {
			return moment().format('YYYY-MM-DD');
		},
	},

	methods: {
		adjustDate(method, params) {
			if(!this.modelValue) {
				return;
			}

			this.menu = false;

			const mDate = moment(this.$formatter.date(this.modelValue));

			if(!mDate.isValid()) {
				return;
			}

			this.model[this.name] = mDate[method](params).format('YYYY-MM-DD');
			this.valueOnFocus = this.model[this.name];
			this.$emit('change', this.model[this.name], {fromKeyUpOrDown: true});
		},

		onKeyDownDown() {
			this.adjustDate('subtract', {days: 1});
		},

		onKeyDownUp() {
			this.adjustDate('add', {days: 1});
		},

		setDateFromModelValue() {
			const value = this.$formatter.date(this.modelValue);

			this.date = Date.parse(value) && moment(value).isValid() ? value : this.dateWhenEmpty;
		},

		prevent18991230() {
			if(this.model[this.name] === '1899-12-30') {
				this.model[this.name] = '';
			}
		},

		//When user inputs a value such as 18 -> 2018
		onChange(value) {
			clearTimeout(this.onChangeTimeout);

			this.onChangeTimeout = setTimeout(() => {
				const oldVal = this.valueOnFocus;

				this.model[this.name] = this.$formatter.date(value);

				this.prevent18991230();

				if(oldVal !== this.model[this.name]) {
					this.$emit('change', this.model[this.name]);
					this.valueOnFocus = this.model[this.name];
				}
			}, 100);
		},

		onSelectDate(value) {
			if(this.type === 'month') {
				value = `${value}-01`;
			}

			this.model[this.name] = this.$formatter.date(value);
			this.prevent18991230();
			this.$refs.menu.save(value);
			this.$emit('input', value);
			this.$emit('change', value);

			this.$tabOrderToNextField(this.tabOrderName || this.name);
		},

		focus() {
			this.valueOnFocus = this.model[this.name];
			if(this.$refs.field) {
				this.$refs.field.focus();
			}
		},

		resetValidation() {
			this.$refs.field.resetValidation();
		},

		onFocus() {
			this.valueOnFocus = this.model[this.name];
			if(this.openOnFocus && !this.menu) {
				this.menu = true;
			}

			this.$eventBus.$emit('Help:SetFromFieldFocus', {
				model: this.model,
				name:  this.name,
			});

			try {
				const $input = get(this.$refs, 'field.$refs.input', null);

				if($input) {
					$input.select();
					$input.setSelectionRange(0, ($input.value || '').length)
				}
			} catch{}
		},

		onBlur() {
			this.resetValidation();

			// this.$emit('blur', event);
		},
	},
}
</script>

<style scoped>

</style>
