/**
 * Created by simon on 2020-02-05.
 */

import {isEmpty, isString, isFunction, isArray, find, includes, forEach} from 'lodash';

import Store from '../store/index.js';

const isValid = (value, data, store) => {
	if(isString(value) && !data[value]) {
		return false;
	} else if(isArray(value.anyOf) && !find(value.anyOf, (value) => data[value])) {
		return false;
	} else if(isFunction(value) && !value(store)) {
		return false;
	}

	return true;
};

class AccessRestriction {
	constructor({Roles = [], RequirePlus = false, RequiredLicensOption = null, RequiredUserOption = null, UserAccountOptions, PortalInfoOptions = [], FtgInfoOptions = []}) {
		this.Roles                = Roles;
		this.UserAccountOptions   = UserAccountOptions;
		this.PortalInfoOptions    = PortalInfoOptions;
		this.FtgInfoOptions       = FtgInfoOptions;
		this.RequiredLicensOption = RequiredLicensOption;
		this.RequiredUserOption   = RequiredUserOption;
		this.RequirePlus          = RequirePlus;
	}

	hasAccess() {
		const {state: {UserAccount, PortalInfo, FtgInfo, HasPlus, HasLicensOption, HasUserOption}} = Store;

		const RequirePlus = isFunction(this.RequirePlus) ? this.RequirePlus(Store.state) === true : this.RequirePlus === true;

		if(RequirePlus && !HasPlus) {
			return false;
		}

		if(this.RequiredLicensOption && HasLicensOption[this.RequiredLicensOption] !== true) {
			return false;
		}

		if(this.RequiredUserOption && HasUserOption[this.RequiredUserOption] !== true) {
			return false;
		}

		if(!isEmpty(this.Roles) && !includes(this.Roles, UserAccount.Roll)) {
			return false;
		}

		if(!isEmpty(this.PortalInfoOptions)) {
			let validPortalInfo = true;

			forEach(this.PortalInfoOptions, (value) => {
				validPortalInfo = validPortalInfo && isValid(value, PortalInfo, Store);
			});

			if(!validPortalInfo) {
				return false;
			}
		}

		if(!isEmpty(this.FtgInfoOptions)) {
			let validFtgInfo = true;

			forEach(this.FtgInfoOptions, (value) => {
				validFtgInfo = validFtgInfo && isValid(value, FtgInfo, Store);
			});

			if(!validFtgInfo) {
				return false;
			}
		}

		if(!isEmpty(this.UserAccountOptions)) {
			let validUserAccount = true;

			forEach(this.UserAccountOptions, (value) => {
				validUserAccount = validUserAccount && isValid(value, UserAccount, Store);
			});

			if(!validUserAccount) {
				return false;
			}
		}

		return true;
	}
}

export default AccessRestriction;
