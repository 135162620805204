/**
 * Created by simon on 2019-12-17.
 */


export default (value) => {
	const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

	return value && emailRegex.test(value);
};
