import Vue               from 'vue'
import Router            from 'vue-router'
import Http404           from '../components/Http404.vue';
import store             from '../store/index.js';
import AccessRestriction from './AccessRestriction.js'

Vue.use(Router);

const MobileAppStaticPagesQuestions = () => import(/* webpackChunkName: "MobileAppStaticPagesQuestions" */ '../components/MobileAppStaticPages/Questions.vue');
const MobileAppStaticPagesTerms     = () => import(/* webpackChunkName: "MobileAppStaticPagesTerms" */ '../components/MobileAppStaticPages/Terms.vue');
const Dashboard                     = () => import(/* webpackChunkName: "Dashboard" */ '../components/Dashboard/Dashboard.vue');
const PortalFeedAdmin               = () => import(/* webpackChunkName: "PortalFeed" */ '../components/PortalFeed/PortalFeedAdmin.vue');
const LonebeskedSaldon              = () => import(/* webpackChunkName: "LonebeskedSaldon" */ '../components/LonebeskedSaldon/LonebeskedSaldon.vue');
const PortalInfo                    = () => import(/* webpackChunkName: "PortalInfo" */ '../components/Admin/PortalInfo.vue');
const Ersattningar                  = () => import(/* webpackChunkName: "Ersattningar" */ '../components/Ersattningar/Ersattningar.vue');
const Tidrapport                    = () => import(/* webpackChunkName: "Tidrapport" */ '../components/TimeReporting/Tidrapport.vue');
const AbsenceReports                = () => import(/* webpackChunkName: "AbsenceReports" */ '../components/TimeReporting/AbsenceReports.vue');
const Kalendarie                    = () => import(/* webpackChunkName: "Kalendarie" */ '../components/TimeReporting/Kalendarie.vue');
const Stamplingar                   = () => import(/* webpackChunkName: "Stamplingar" */ '../components/TimeReporting/Stamplingar.vue');
const LeaveApplications             = () => import(/* webpackChunkName: "LeaveApplications" */ '../components/LeaveApplications/LeaveApplications.vue');
const LeavePlannerOverview          = () => import(/* webpackChunkName: "LeavePlannerOverview" */ '../components/LeavePlanner/LeavePlannerOverview.vue');
const LeavePlanner                  = () => import(/* webpackChunkName: "LeavePlanner" */ '../components/LeavePlanner/LeavePlanner.vue');
const AbsenceCalendar               = () => import(/* webpackChunkName: "AbsenceCalendar" */ '../components/AbsenceCalendar/AbsenceCalendar.vue');
const AdminUsers                    = () => import(/* webpackChunkName: "AdminUsers" */ '../components/Admin/Users/Users.vue');
const AdminReminders                = () => import(/* webpackChunkName: "AdminReminders" */ '../components/Admin/Reminders/Reminders.vue');
const Documents                     = () => import(/* webpackChunkName: "Documents" */ '../components/Documents/Documents.vue');
const Narvarostatus                 = () => import(/* webpackChunkName: "Narvarostatus" */ '../components/Narvarostatus/Narvarostatus.vue');
const Attestera                     = () => import(/* webpackChunkName: "Attestera" */ '../components/Attest/Attestera.vue');
const ApproveTime                   = () => import(/* webpackChunkName: "ApproveTime" */ '../components/ApproveTime/ApproveTime.vue');
const FK3059Assistent               = () => import(/* webpackChunkName: "FK3059Assistent" */ '../components/FK3059/Assistent/FK3059Assistent.vue');
const FK3059Anordnare               = () => import(/* webpackChunkName: "FK3059Anordnare" */ '../components/FK3059/Anordnare/FK3059Anordnare.vue');
const Schedule                      = () => import(/* webpackChunkName: "Schedule" */ '../components/Bemanna/Employee/Schedule.vue');
const BemannaAdminInitial           = () => import(/* webpackChunkName: "BemannaAdminInitial" */ '../components/Bemanna/Admin/BemAdminNavBar.vue');
const BemannaBehov                  = () => import(/* webpackChunkName: "BemannaBehov" */ '../components/Bemanna/Admin/Behov.vue');
const BemannaOverview               = () => import(/* webpackChunkName: "BemannaOverview" */ '../components/Bemanna/Admin/Overview.vue');
const BemannaPlanering              = () => import(/* webpackChunkName: "BemannaPlanering" */ '../components/Bemanna/Admin/Planering.vue');
const BemannaPassbyten              = () => import(/* webpackChunkName: "BemannaPassbyten" */ '../components/Bemanna/Admin/Passbyten.vue');
const Personuppgifter               = () => import(/* webpackChunkName: "Personuppgifter" */ '../components/Personuppgifter/Personuppgifter.vue');
const Reserakning                   = () => import(/* webpackChunkName: "Reserakning" */ '../components/Reserakning/Reserakning.vue');
const Messages                      = () => import(/* webpackChunkName: "Messages" */ '../components/Messages/Messages.vue');
const TidrapportPrint               = () => import(/* webpackChunkName: "TidrapportPrint" */ '../components/Rapporter/TidrapportPrint.vue');
const ErsattningarPrint             = () => import(/* webpackChunkName: "ErsattningarPrint" */ '../components/Rapporter/ErsattningarPrint.vue');
const AttestlistaPrint              = () => import(/* webpackChunkName: "AttestlistaPrint" */ '../components/Rapporter/AttestlistaPrint.vue');
const ReserakningPrint              = () => import(/* webpackChunkName: "ReserakningPrint" */ '../components/Rapporter/ReserakningPrint.vue');
const ProjektlistaPrint             = () => import(/* webpackChunkName: "ProjektlistaPrint" */ '../components/Rapporter/ProjektlistaPrint.vue');
const UserAppSettings               = () => import(/* webpackChunkName: "UserAppSettings" */ '../components/UserAppSettings.vue');
const NearAccidentsReports          = () => import(/* webpackChunkName: "NearAccidentsReports" */ '../components/NearAccidentsReports/NearAccidentsReports.vue');

const router = new Router({
	mode:   'history',
	routes: [
		{
			path:     '/',
			redirect: '/dashboard',
		},
		{
			path:     '/questions',
			redirect: '/app/questions',
		},
		{
			path:      '/app/questions',
			name:      'AppQuestions',
			component: MobileAppStaticPagesQuestions,
		},
		{
			path:      '/app/terms',
			name:      'AppTerms',
			component: MobileAppStaticPagesTerms,
		},
		{
			path:      '/dashboard',
			name:      'Dashboard',
			component: Dashboard,
		},
		{
			path:      '/userAppSettings',
			name:      'UserAppSettings',
			component: UserAppSettings,
		},
		{
			path:      '/rapporter/tidrapport',
			name:      'TidrapportPrint',
			component: TidrapportPrint,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({
					PortalInfoOptions: [
						{anyOf: ['EnableUtskriftTidrapport']},
					],
				}),
			},
		},
		{
			path:      '/rapporter/ersattningar',
			name:      'ErsattningarPrint',
			component: ErsattningarPrint,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({
					PortalInfoOptions: [
						{anyOf: ['EnableUtskriftErsattningar']},
					],
				}),
			},
		},
		{
			path:      '/rapporter/projektlista',
			name:      'ProjektlistaPrint',
			component: ProjektlistaPrint,
			meta:      {
				accessRestriction: new AccessRestriction({
					Roles:             ['LIC', 'ADM', 'FTG', 'LSS', 'BRU', 'AVD'],
					RequirePlus:       true,
					FtgInfoOptions:    [{anyOf: ['UseLSS', 'OpenResEnhet1', 'OpenResEnhet2', 'OpenResEnhet3', 'OpenResEnhet4', 'OpenResEnhet5', 'OpenResEnhet6']}],
					PortalInfoOptions: [
						{anyOf: ['EnableUtskriftProjektlista']},
					],
				}),
			},
		},
		{
			path:      '/rapporter/reserakning',
			name:      'ReserakningPrint',
			component: ReserakningPrint,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:       true,
					PortalInfoOptions: [
						{anyOf: ['EnableUtskriftReserakning']},
					],
				}),
			},
		},
		{
			path:      '/rapporter/attestlista',
			name:      'AttestlistaPrint',
			component: AttestlistaPrint,
			meta:      {
				accessRestriction: new AccessRestriction({
					Roles:             ['LIC', 'ADM', 'FTG', 'LSS', 'BRU', 'AVD'],
					PortalInfoOptions: [
						{anyOf: ['EnableUtskriftAttestTider', 'EnableUtskriftAttestErsattningar']},
					],
				}),
			},
		},
		{
			path:      '/messages',
			name:      'Messages',
			component: Messages,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:       true,
					PortalInfoOptions: [
						{anyOf: ['EnableMeddelanden']},
					],
				}),
			},
		},
		{
			path:      '/lonebesked',
			name:      'LonebeskedSaldon',
			component: LonebeskedSaldon,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({
					PortalInfoOptions: [
						{anyOf: ['EnableLonebeskedView', 'EnableSaldonView']},
					],
				}),
			},
		},
		{
			path:      '/documents',
			name:      'Documents',
			component: Documents,
			meta:      {
				accessRestriction: new AccessRestriction({RequirePlus: true, PortalInfoOptions: [{anyOf: ['EnableDocumentsView', 'EnableDocumentsUpload']}]}),
			},
		},
		{
			path:      '/presenceStatus',
			name:      'PresenceStatus',
			component: Narvarostatus,
			meta:      {
				accessRestriction: new AccessRestriction({PortalInfoOptions: ['EnableNarvarostatusView']}),
			},
		},
		{
			path:      '/approveTime',
			name:      'ApproveTime',
			component: ApproveTime,
			meta:      {
				accessRestriction: new AccessRestriction({
					PortalInfoOptions:  [{anyOf: ['EnableGodkannaView']}],
					FtgInfoOptions:     ['KalUseLonReady'],
					UserAccountOptions: [({state: {UserAccount}}) => UserAccount.Roll === 'PER' || ['FTG', 'ADM', 'LIC', 'AVD'].includes(UserAccount.Roll)],
				}),
			},
		},
		{
			path:      '/attest',
			name:      'Attestera',
			component: Attestera,
			meta:      {
				accessRestriction: new AccessRestriction({PortalInfoOptions: [{anyOf: ['EnableAttestTiderView', 'EnableAttestErsattningarView']}], FtgInfoOptions: ['KalUseAttest'], UserAccountOptions: [({state: {UserAccount}}) => UserAccount.Roll !== 'PER']}),
			},
		},
		{
			path:      '/admin/portal',
			name:      'AdminPortalInfo',
			component: PortalInfo,
			meta:      {
				accessRestriction: new AccessRestriction({Roles: ['LIC', 'ADM', 'FTG']}),
			},
		},
		{
			path:      '/admin/users',
			name:      'AdminUsers',
			component: AdminUsers,
			meta:      {
				accessRestriction: new AccessRestriction({Roles: ['LIC', 'ADM']}),
			},
		},
		{
			path:      '/admin/reminders',
			name:      'AdminReminders',
			component: AdminReminders,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus: true,
					Roles:       ['LIC', 'ADM'],
				}),
			},
		},
		{
			path:      '/compensations',
			name:      'Compensations',
			component: Ersattningar,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({
					PortalInfoOptions: [(store) => {
						const {state: {HasPlus, PortalInfo = {}}} = store;
						const {EnableErsattningarView, EnableTraktamenteView, EnableMilersattningView, EnableUtlaggView, EnableRepresentationView} = PortalInfo;

						if(!HasPlus) {
							return EnableErsattningarView;
						}

						return EnableErsattningarView || EnableTraktamenteView || EnableMilersattningView || EnableUtlaggView || EnableRepresentationView;
					}],
				}),
			},
		},
		{
			path:      '/calendar',
			name:      'Kalendarie',
			component: Kalendarie,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({PortalInfoOptions: ['EnableKalendarieView']}),
			},
		},
		{
			path:      '/timereporting',
			name:      'Tidrapport',
			component: Tidrapport,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({PortalInfoOptions: ['EnableTidrapportView']}),
			},
		},
		{
			path:      '/absenceReports',
			name:      'AbsenceReports',
			component: AbsenceReports,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({
					RequiredLicensOption: 'HRTIL',
					Roles:                ['LIC', 'ADM', 'FTG', 'AVD', 'PER'],
					PortalInfoOptions:    [(store) => {
						const {state: {UserAccount, HasUserOption, PortalInfo}} = store;

						if(UserAccount.Roll === 'PER' || (UserAccount.Roll === 'AVD' && UserAccount.AnstNr)) {
							return PortalInfo.EnableAbsenceReports;
						}

						return HasUserOption.HRTIL && PortalInfo.EnableAbsenceReports;
					}],
				}),
			},
		},
		{
			path:      '/stamplingar',
			name:      'Stamplingar',
			component: Stamplingar,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({PortalInfoOptions: ['EnableStamplingView']}),
			},
		},
		{
			path:      '/infopanel',
			name:      'PortalFeedAdmin',
			component: PortalFeedAdmin,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:        ({HasLicensOption}) => !HasLicensOption.HRTIL,
					RequiredUserOption: 'HRTIL',
					PortalInfoOptions:  ['EnablePortalFeed'],
				}),
			},
		},
		{
			path:      '/leaveapplications',
			name:      'LeaveApplications',
			component: LeaveApplications,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:       ({HasLicensOption}) => !HasLicensOption.HRTIL,
					PortalInfoOptions: ['EnableLeaveApplications'],
					Roles:             ['LIC', 'ADM', 'FTG', 'AVD', 'PER'],
				}),
			},
		},
		{
			path:      '/leaveplanner/overview',
			name:      'LeavePlannerOverview',
			component: LeavePlannerOverview,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequiredUserOption: 'HRTIL',
					PortalInfoOptions:  ['EnableLeavePlanner'],
					Roles:              ['LIC', 'ADM', 'FTG', 'AVD'],
				}),
			},
		},
		{
			path:      '/leaveplanner/:Id',
			name:      'LeavePlanner',
			component: LeavePlanner,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequiredUserOption: 'HRTIL',
					PortalInfoOptions:  ['EnableLeavePlanner'],
					Roles:              ['LIC', 'ADM', 'FTG', 'AVD'],
				}),
			},
		},
		{
			path:      '/absencecalendar',
			name:      'AbsenceCalendar',
			component: AbsenceCalendar,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({
					RequiredLicensOption: 'HRTIL',
					Roles:                ['LIC', 'ADM', 'FTG', 'AVD', 'PER'],
					PortalInfoOptions:    [(store) => {
						const {state: {UserAccount, HasUserOption, PortalInfo}} = store;

						if(UserAccount.Roll === 'PER' || (UserAccount.Roll === 'AVD' && UserAccount.AnstNr)) {
							return PortalInfo.EnableAbsenceCalendar;
						}

						return HasUserOption.HRTIL && PortalInfo.EnableAbsenceCalendar;
					}],
				}),
			},
		},
		{
			path:      '/nearAccidentsReports',
			name:      'NearAccidentsReports',
			component: NearAccidentsReports,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequiredLicensOption: 'HRTIL',
					Roles:                ['LIC', 'ADM', 'FTG', 'AVD', 'PER'],
					PortalInfoOptions:    [(store) => {
						const {state: {UserAccount, HasUserOption, PortalInfo}} = store;

						if(UserAccount.Roll === 'PER' || (UserAccount.Roll === 'AVD' && UserAccount.AnstNr)) {
							return PortalInfo.EnableNearAccidentsReports;
						}

						return HasUserOption.HRTIL && PortalInfo.EnableNearAccidentsReports;
					}],
				}),
			},
		},
		{
			path:      '/employeeCard',
			name:      'Personuppgifter',
			component: Personuppgifter,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({
					PortalInfoOptions: [({state: {PortalInfo, HasPlus}}) => PortalInfo.EnableAdressandringView || PortalInfo.EnablePerUtbUpd || PortalInfo.EnablePerKontaktView || (PortalInfo.EnableBarnRegView && HasPlus)],
				}),
			},
		},
		{
			path:      '/travelExpenses',
			name:      'TravelExpenses',
			component: Reserakning,
			meta:      {
				RequireAtleastOneEmployeeInRegistry: true,
				accessRestriction:                   new AccessRestriction({
					RequirePlus:       true,
					PortalInfoOptions: ['EnableReserakningView'],
				}),
			},
		},
		{
			path:      '/fk3059/assistent',
			name:      'FK3059Assistent',
			component: FK3059Assistent,
			meta:      {
				accessRestriction: new AccessRestriction({
					FtgInfoOptions:     ['UseFKSignering', 'UseLSS'],
					PortalInfoOptions:  ['AcceptedDebitAccount'],
					Roles:              ['PER', 'AVD'],
					UserAccountOptions: {AnstNr: (store) => store.state.UserAccount.AnstNr},
				}),
			},
		},
		{
			path:      '/fk3059/anordnare',
			name:      'FK3059Anordnare',
			component: FK3059Anordnare,
			meta:      {
				accessRestriction: new AccessRestriction({
					FtgInfoOptions:     ['UseFKSignering', 'UseLSS'],
					PortalInfoOptions:  ['AcceptedDebitAccount'],
					Roles:              ['AVD', 'BRU', 'LSS', 'FTG', 'ADM', 'LIC'],
					UserAccountOptions: {Roll: (store) => store.state.UserAccount.Roll !== 'PER'},
				}),
			},
		},
		{
			path:      '/schedule',
			name:      'Schedule',
			component: Schedule,
			meta:      {
				accessRestriction: new AccessRestriction({
					PortalInfoOptions:  ['EnableBemanningView'],
					UserAccountOptions: {AnstNr: (store) => store.state.UserAccount.AnstNr},
				}),
			},
		},
		{
			path:      '/bemanna',
			name:      'Bemanna',
			component: BemannaAdminInitial,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:        true,
					PortalInfoOptions:  ['EnableBemanningView'],
					UserAccountOptions: {Roll: (store) => store.state.UserAccount.Roll !== 'PER', UserOption: (store) => store.state.HasUserOption.CPBEM},
				}),
			},
		},
		{
			path:      '/bemanna/behov',
			name:      'BemBehov',
			component: BemannaBehov,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:        true,
					PortalInfoOptions:  ['EnableBemanningView'],
					UserAccountOptions: {Roll: (store) => store.state.UserAccount.Roll !== 'PER', UserOption: (store) => store.state.HasUserOption.CPBEM},
				}),
			},
		},
		{
			path:      '/bemanna/overview',
			name:      'BemOverview',
			component: BemannaOverview,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:        true,
					PortalInfoOptions:  ['EnableBemanningView'],
					UserAccountOptions: {Roll: (store) => store.state.UserAccount.Roll !== 'PER', UserOption: (store) => store.state.HasUserOption.CPBEM},
				}),
			},
		},
		{
			path:      '/bemanna/planering',
			name:      'BemPlanner',
			component: BemannaPlanering,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:        true,
					PortalInfoOptions:  ['EnableBemanningView'],
					UserAccountOptions: {Roll: (store) => store.state.UserAccount.Roll !== 'PER', UserOption: (store) => store.state.HasUserOption.CPBEM},
				}),
			},
		},
		{
			path:      '/bemanna/passbyten',
			name:      'BemTrade',
			component: BemannaPassbyten,
			meta:      {
				accessRestriction: new AccessRestriction({
					RequirePlus:       true,
					PortalInfoOptions: {
						EnableBemanningView: true,
						PassbyteTyp:         (store) => store.state.PortalInfo.PassbyteTyp !== 'X',
					},
					UserAccountOptions: {Roll: (store) => store.state.UserAccount.Roll !== 'PER', UserOption: (store) => store.state.HasUserOption.CPBEM},
				}),
			},
		},

		{
			path:      '*',
			name:      'Http404',
			component: Http404,
		},
	],
});

router.checkHasUnsavedChanges = (callback) => {
	const {$children = []} = router.app;
	const [appView] = $children;

	if(appView) {
		return appView.checkHasUnsavedChanges((wantsToLeave) => {
			callback(wantsToLeave);
		});
	}

	return callback();
};

router.checkAccess = async (route, {autoNavigate = false} = {}) => {
	if('string' === typeof route) {
		route = router.options.routes.find((item) => item.name === route);
	}

	if(!route) {
		return true;
	}

	const {meta: {accessRestriction} = {}} = route;

	const hasAccess = accessRestriction ? await accessRestriction.hasAccess() : true;

	if(!hasAccess && autoNavigate) {
		router.push({name: 'Dashboard'});

		return hasAccess;
	}

	return hasAccess;
};

router.beforeEach((to, from, next) => {
	router.checkHasUnsavedChanges(next);
});

router.afterEach(async (to) => {
	if(to && to.meta && to.meta.RequireHasPlus && store.state.Authenticated && !store.state.HasPlus) {
		router.go(-1);
	}

	if(store.getters.HasLoadedAuthNecessities && !await router.checkAccess(to)) {
		console.log('Router denied access to route, redirecting..');
		router.push({name: 'Dashboard'});
	}
});

export default router
