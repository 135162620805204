/**
 * Created by simon on 2018-12-27.
 */

import _                 from 'lodash';
import moment            from 'moment';
import Vue               from 'vue';
import {mapState}        from 'vuex';
import store             from '../store/index.js';
import HttpRequester     from '../lib/HttpRequester.js';
import Formatter         from '../lib/Formatter.js';
import sharedViewFilters from './sharedViewFilters.js';
import downloadFile      from './thirdparty/download.js';

Vue.prototype.$eventBus  = new Vue();
Vue.prototype.$http      = HttpRequester;
Vue.prototype.$formatter = Formatter;

const DV = {
	install(Vue) {
		Vue.mixin({
			methods: {
				$validateForm({ref = 'form', showMsgError = true} = {}) {
					const form = this.$refs[ref];

					if(!form) {
						console.warn(`Form with reference ${ref} does not exist.`)
						
						return false;
					}


					if(!form.validate()) {
						const {inputs = []}     = form;
						const firstInvalidInput = inputs.find((item) => !item.valid);

						this.$changeTabsToShowComponent(firstInvalidInput);

						if(firstInvalidInput.$el || firstInvalidInput.$refs.input) {
							this.$vuetify.goTo(firstInvalidInput.$el || firstInvalidInput.$refs.input, {offset: 100});
						}

						if(showMsgError) {
							for(const input of inputs) {
								if(input.validations.length > 0) {
									this.$msgError(input.validations[0]);
									input.focus();
									break;
								}
							}
						}

						return false;
					}

					return true;
				},

				$downloadFile(fileContent, fileName) {
					downloadFile(fileContent, fileName);
				},

				$buildURL(url, {apiRoute = false, skipBaseURI = false} = {}) {
					//Redirect to API server when running in UTV mode (since it's different ports)
					const {VUE_APP_BASE_URI, VUE_APP_API_URI} = process.env;

					if(skipBaseURI === false && url.slice(0, VUE_APP_BASE_URI.length) !== VUE_APP_BASE_URI) {
						if(url.slice(0, 1) === '/') {
							url = url.slice(1);
						}
						url = `${VUE_APP_BASE_URI}/${url}`;
					}

					if(apiRoute) {
						const isPrint        = url.startsWith('/print');
						const isFileDownload = url.toLowerCase().startsWith('/filedownload');

						url = `${VUE_APP_API_URI}${url}`;

						if(isPrint || isFileDownload) {
							url = url.replace('/api/', '/');
						}
					}

					return url;
				},

				$openNewTab(url, {target = '_blank', apiRoute = false, skipBaseURI = false} = {}) {
					window.open(this.$buildURL(url, {apiRoute, skipBaseURI}), target);
				},

				$msgSuccess(message) {
					this.$msg({text: message, color: 'success'});
				},

				$msgInfo(message) {
					this.$msg({text: message, color: 'info'});
				},

				$msgError(message) {
					this.$msg({text: message, color: 'error'});
				},

				$msgWarn(message) {
					this.$msg({text: message, color: 'accent'});
				},

				$msg(options = {}) {
					store.dispatch('setSnackbar', options);
				},

				$frontendLog(error, additionalContext = {}) {
					if(error instanceof HttpRequester.BackendError) {
						return;
					}

					this.$tryCatch({
						task: async () => {
							const {fullPath: url, params}                     = this.$route || {};
							const {response = null, code = null, config = {}} = error;
							const {method, url: reqURL, data: requestData, headers, params: reqParams}      = config;
							const {stack, message} = error;

							await this.$http.post('/frontendlogger', {
								message,
								stack,
								url,
								context: {
									url,
									params,
									code,
									httpReq: reqURL ? {
										method,
										url:    reqURL,
										requestData,
										headers,
										params: reqParams,
									} : undefined,
									httpRes: response ? {
										data:    response ? response.data : null,
										status:  response ? response.status : null,
										headers: response ? response.headers : null,
									} : undefined,
									additionalContext,
								},
							}, {
								skipBackendMessageSnackbar: true,
							});
						},
					});
				},

				//Change tabs over to first invalid field
				$changeTabsToShowComponent(component) {
					if(component) {
						let target = component.$parent;

						let horizontalTab = null;

						let horizontalTabIndex = null;

						let sideTab = null;

						let sideTabIndex = null;

						let previous = [];

						let ToggleCard = null;

						while(target.$parent) {
							if(target.setActiveTab) {
								if(sideTab) {
									horizontalTab = target;
									horizontalTabIndex = _.findIndex(target.$children, (item) => item._uid === _.last(previous)._uid);
									previous = [];
								} else if(target.$options && ['SideTabLayout', 'HorizontalTabLayout'].includes(target.$options._componentTag)) {
									sideTab     = target;
									previous    = previous.reverse();
									target      = previous.shift();

									//Exclude FixedLayout as it's not part of tabs
									const filteredChildren = target.$children.filter((item) => item.$options._componentTag !== 'FixedLayout');

									sideTabIndex = _.findIndex(filteredChildren, (item) => item._uid === _.first(previous)._uid);
									previous    = [];
									target      = target.$parent;
								}
							} else if(target.$options && target.$options._componentTag === 'ToggleCard') {
								ToggleCard = target;
							}

							if(horizontalTab && sideTab) {
								break;
							}

							//If in dialog, then don't try to swap tabs etc
							if(target && target.$options && target.$options._componentTag === 'Dialog') {
								break;
							}

							previous.push(target);
							target = target.$parent;
						}

						if(horizontalTab) {
							horizontalTab.setActiveTab(horizontalTabIndex);
						}

						if(sideTab) {
							sideTab.setActiveTab(sideTabIndex);
						}

						if(ToggleCard) {
							ToggleCard.open();
						}
					}
				},

				$registerEventSourceHandler(eventName, handler = () => ({})) {
					this.$eventSourceHandlers = this.$eventSourceHandlers || {};
					this.$eventSourceHandlers[eventName] = handler.bind(this);
					this.$eventBus.$on(`WebSocketClient:${eventName}`, this.$eventSourceHandlers[eventName], this);
				},

				$isBirthday(PersNr) {
					const now = new Date();

					return PersNr && PersNr.length === 12 && (PersNr || '').slice(4, 8) === `${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}`;
				},

				copyTextToClipboard(text) {
					if(!this.clipboardAPIEnabled) {
						return;
					}

					navigator.clipboard.writeText(text);
				},

				$nl2br(text) {
					return (text || '').replace(/\r\n|\r|\n/g, '<br/>')
				},

				onBlurPersNr(model = this.model, fieldName = 'PersNr') {
					model[fieldName] = this.$formatter.persNr(model[fieldName]);
				},

				onFocusPersNr(model = this.model, fieldName = 'PersNr') {
					model[fieldName] = model[fieldName].replace(/\D+/g, '');
				},

				setDefaultBruRes(target) {
					const {PerStatus = {}} = this.SharedViewFilters.selectedEmployee;

					const item = target || this.model || this.item;

					if(this.FtgInfo.UseLSS && !item.UseBrukare && PerStatus.UseBrukare) {
						item.UseBrukare = PerStatus.UseBrukare;
						item.Brukare = PerStatus.Brukare;
					}

					if(this.FtgInfo.OpenResEnhet1 && this.FtgInfo.RegResEnh1 && !item.UseResEnhet1) {
						item.UseResEnhet1 = PerStatus.UseResEnhet1;
						item.ResEnhet1    = PerStatus.ResEnhet1;
					}

					if(this.FtgInfo.OpenResEnhet2 && this.FtgInfo.RegResEnh2 && !item.UseResEnhet2) {
						item.UseResEnhet2 = PerStatus.UseResEnhet2;
						item.ResEnhet2    = PerStatus.ResEnhet2;
					}

					if(this.FtgInfo.OpenResEnhet3 && this.FtgInfo.RegResEnh3 && !item.UseResEnhet3) {
						item.UseResEnhet3 = PerStatus.UseResEnhet3;
						item.ResEnhet3    = PerStatus.ResEnhet3;
					}

					return item;
				},

				isAcceptableDocumentType(type) {
					if(
						![
							'application/pdf',
							'application/msword',
						].includes(type) &&
						!type.startsWith('text') &&
						!type.startsWith('image') &&
						!type.startsWith('application/vnd.openxmlformats') &&
						!type.startsWith('application/vnd.ms-excel')  &&
						!type.startsWith('application/vnd.oasis')  &&
						!type.startsWith('application/vnd.ms-powerpoint')
					) {
						this.$msgError(this.$t('DocumentFileExtensionError'));

						return false;
					}

					return true;
				},
			},

			destroyed() {
				const eventSourceHandlers = this.$eventSourceHandlers || {};
				const registeredEvents = Object.keys(eventSourceHandlers);

				for(const eventName of registeredEvents) {
					if(eventSourceHandlers[eventName]) {
						this.$eventBus.$off(`WebSocketClient:${eventName}`, eventSourceHandlers[eventName], this);
					}
				}
			},

			computed: {
				...mapState(['UserAccount', 'HasPlus', 'HasWebLon', 'HasLicensOption', 'HasUserOption', 'PortalInfo', 'Auth', 'FtgInfo']),

				isAdmin() {
					return ['LIC', 'ADM'].includes(this.UserAccount.Roll);
				},

				SharedViewFilters: {
					get () {
						return sharedViewFilters;
					},

					set() {
						throw new Error('Set is not allowed to be called on SharedViewFilters');
					},
				},

				anyDialogsVisible() {
					return document.getElementsByClassName('v-dialog__content--active').length > 0;
				},

				activeElementIsInput() {
					return document.activeElement && document.activeElement.tagName === 'INPUT';
				},

				clipboardAPIEnabled() {
					return navigator && navigator.clipboard && navigator.clipboard.writeText;
				},

				HasLoadedAuthNecessities() {
					return store.state.HasLoadedAuthNecessities;
				},

				NOLL_GUID() {
					return '00000000-0000-0000-0000-000000000000';
				},

				legacyPortalEnabled() {
					return moment().isSameOrBefore(process.env.VUE_APP_LEGACY_PORTAL_END_DATE);
				},

				anyBruResEnabled() {
					return this.FtgInfo.UseLSS || (
						(this.FtgInfo.OpenResEnhet1 && this.FtgInfo.RegResEnh1) ||
						(this.FtgInfo.OpenResEnhet2 && this.FtgInfo.RegResEnh2) ||
						(this.FtgInfo.OpenResEnhet3 && this.FtgInfo.RegResEnh3) ||
						(this.FtgInfo.OpenResEnhet4 && this.FtgInfo.RegResEnh4) ||
						(this.FtgInfo.OpenResEnhet5 && this.FtgInfo.RegResEnh5) ||
						(this.FtgInfo.OpenResEnhet6 && this.FtgInfo.RegResEnh6)
					)
				},

				currentTidKodGrupp() {
					if(['PER', 'AVD'].includes(this.UserAccount.Roll)) {
						const {selectedEmployee} = this.SharedViewFilters;

						if(selectedEmployee && (this.UserAccount.Roll === 'PER' || (this.UserAccount.Roll === 'AVD' && this.UserAccount.AnstNr === selectedEmployee.AnstNr))) {
							return selectedEmployee.TidKodGrupp;
						}
					}

					return this.NOLL_GUID;
				},

				currentLonKodGrupp() {
					if(['PER', 'AVD'].includes(this.UserAccount.Roll)) {
						const {selectedEmployee} = this.SharedViewFilters;

						if(selectedEmployee && (this.UserAccount.Roll === 'PER' || (this.UserAccount.Roll === 'AVD' && this.UserAccount.AnstNr === selectedEmployee.AnstNr))) {
							return selectedEmployee.LonKodGrupp;
						}
					}

					return this.NOLL_GUID;
				},

				isDeviceAndroid() {
					return !!navigator.userAgent.match(/Android/);
				},

				isDeviceIOS() {
					return !!navigator.userAgent.match(/iPhone|iPad|iPod/);
				},

				isBrowserIOSSafari() {
					return this.isDeviceIOS &&
						!!window.navigator.userAgent.match(/Safari/) &&
						!this.isBrowserIOSChrome &&
						!this.isBrowserIOSFirefox &&
						!this.isBrowserIOSInAppFacebook &&
						!this.isBrowserIOSInAppLinkedin &&
						!this.isBrowserIOSInAppInstagram &&
						!this.isBrowserIOSInAppThreads &&
						!this.isBrowserIOSInAppTwitter;
				},

				/* Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X)
					 AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75
					 Mobile/14E5239e Safari/602.1 */
				isBrowserIOSChrome() {
					return this.isDeviceIOS && !!navigator.userAgent.match(/CriOS/);
				},

				/* Mozilla/5.0 (iPhone; CPU iPhone OS 16_5 like Mac OS X)
				AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/114.1 Mobile/15E148 Safari/605.1.15 */
				isBrowserIOSFirefox() {
					return this.isDeviceIOS && !!window.navigator.userAgent.match(/FxiOS/);
				},

				isBrowserIOSInAppFacebook() {
					if (!this.isDeviceIOS) {
						return false;
					}

					return !!window.navigator.userAgent.match(/FBAN|FBAV/);
				},

				isBrowserIOSInAppLinkedin() {
					if (!this.isDeviceIOS) {
						return false;
					}

					return !!window.navigator.userAgent.match(/LinkedInApp/);
				},

				isBrowserIOSInAppInstagram() {
					if (!this.isDeviceIOS) {
						return false;
					}


					// TODO: this is incompatible with Instagram/Threads mobile website links.
					// TODO: this solution only works with first-level links
					if (window.document.referrer.match('//l.instagram.com/')) {
						return true;
					}

					return false;
				},

				isBrowserIOSInAppThreads() {
					return this.isBrowserIOSInAppInstagram;
				},

				isBrowserIOSInAppTwitter() {
					if (!this.isDeviceIOS) {
						return false;
					}

					return !!window.document.referrer.match('//t.co/');
				},

				isDesktopWindows() {
					return !!navigator.userAgent.includes('Windows');
				},

				isDesktopMac() {
					return !!navigator.userAgent.includes('Macintosh');
				},

				isDesktopChrome() {
					const userAgent = navigator.userAgent;
					const isChrome = userAgent.includes('Chrome') && !userAgent.includes('Edg'); // Exclude Edge browser
					const isDesktop = userAgent.includes('Windows') || userAgent.includes('Macintosh') || userAgent.includes('Linux');

					return isChrome && isDesktop;
				},

				isDesktopSafari() {
					const userAgent = navigator.userAgent;
					const isSafari = userAgent.includes('Safari') && !userAgent.includes('Chrome') && !userAgent.includes('Edg');
					const isDesktop = userAgent.includes('Macintosh') || userAgent.includes('Windows');

					return isSafari && isDesktop;
				},

				isDesktopEdge() {
					const userAgent = window.navigator.userAgent;
					
					return userAgent.includes('Edg/');
				},
			},
		});
	},
};

export default DV;
