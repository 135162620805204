<template>
	<v-dialog
					v-model="visible"
					v-bind="$attrs"
					v-on="$listeners"
					:width="width"
					:fullscreen="fullscreen"
					:persistent="fullscreen || $attrs.persistent"
					:no-click-animation="fullscreen || $attrs['no-click-animation']"
					:retain-focus="false"
					ref="dialog"
	>
		<template v-slot:activator="{on}">
			<slot name="activator" v-bind="{on}"></slot>
		</template>

		<v-card :disabled="disabled" :loading="loading">
			<template v-if="fullscreen">
				<v-toolbar color="primary" flat dense dark>
					<v-toolbar-title>{{title}}</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<v-btn icon dark @click="hide(true)" :aria-label="$t('Close')">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
			</template>

			<template v-else>
				<slot name="title">
					<v-card-title v-if="title || $slots.title">
						<Title :centered="centeredTitle" style="width: 100%;">
							{{title}}

							<v-btn v-if="titleIconRight" small icon style="float: right;" @click="$emit('clickTitleIconRight', $event)"><v-icon>{{titleIconRight}}</v-icon></v-btn>
						</Title>
						<SubTitle :centered="centeredTitle" v-if="subTitle" style="width: 100%;">{{subTitle}}</SubTitle>
					</v-card-title>
				</slot>
			</template>

			<v-card-text ref="contentContainer" :class="{'mt-3': fullscreen && !noPadding, 'pa-0': noPadding}">
				<slot></slot>
			</v-card-text>
			<v-card-actions>
				<slot name="buttons" v-bind="{hide}"></slot>
			</v-card-actions>
		</v-card>

	</v-dialog>
</template>

<script>
import Title    from './typography/Title.vue';
import SubTitle from './typography/SubTitle.vue';

export default {
	name: 'Dialog',

	props: {
		width: {
			type:    Number,
			default: 590,
		},

		title: {
			type:    String,
			default: '',
		},

		subTitle: {
			type:    String,
			default: '',
		},

		centeredTitle: {
			type:    Boolean,
			default: false,
		},

		fullscreen: {
			type:    Boolean,
			default: false,
		},

		noPadding: {
			type:    Boolean,
			default: false,
		},

		disabled: {
			type:    Boolean,
			default: false,
		},

		titleIconRight: {
			type:    String,
			default: '',
		},

		loading: {
			type:    Boolean,
			default: false,
		},
	},

	data() {
		return {
			visible: false,
		};
	},

	watch: {
		visible(newValue, oldValue) {
			if(newValue === true && oldValue !== true) {
				this.$emit('show');
			} else if(newValue === false && oldValue !== false) {
				this.$emit('hide');
				this.$eventBus.$emit('Help:SetFromCurrentView');
			}

			if(this.visible) {
				this.$nextTick(() => {
					const inputFields = this.$refs.contentContainer
						? this.$refs.contentContainer.getElementsByTagName('input')
						: [];

					for(const field of inputFields) {
						if(field.autofocus) {
							setTimeout(() => {
								field.focus();
							}, 100);
							break;
						}
					}
				});
			}
		},

		'$vuetify.application.left'() {
			this.setDialogMargins();
		},

		'$vuetify.application.right'() {
			this.setDialogMargins();
		},
	},

	mounted() {
		this.$eventBus.$on('BreakpointChanged', () => {
			if(this.visible) {
				this.setDialogMargins();
			}
		});
	},

	methods: {
		setDialogMargins() {
			if(!this.visible) {
				return;
			}

			try {
				if(this.fullscreen) {
					const childIndex = this.$refs.dialog.$children.findIndex((item) => item.$el.tagName === 'DIV');

					if(childIndex >= 0) {
						this.$refs.dialog.$children[childIndex].$el.children[0].style.width = `${(window.innerWidth - this.$vuetify.application.right)}px`;
					}
				} else {
					this.$refs.dialog.$children[0].$el.children[0].style['margin-left'] = `${this.$vuetify.application.left}px`;
					this.$refs.dialog.$children[0].$el.children[0].style['margin-right'] = `${this.$vuetify.application.right}px`;
				}
			} catch{}
		},

		show(onShow = null) {
			this.visible = true;

			this.$nextTick(() => {
				this.setDialogMargins();

				if(onShow) {
					onShow();
				}
			});
		},

		hide(emitClose = false) {
			this.visible = false;

			if(emitClose === true) {
				this.$emit('close');
			}
		},

		isVisible() {
			return this.visible === true;
		},
	},

	components: {
		Title,
		SubTitle,
	},
}
</script>

<style scoped>

</style>
