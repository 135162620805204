<template>
	<v-dialog
			v-model="visible"
			v-bind="$attrs"
			v-on="$listeners"
			fullscreen
			no-click-animation
			:retain-focus="false"
			ref="dialog"
	>
		<v-app-bar dark color="primary" dense>
			<v-spacer></v-spacer>
			<v-toolbar-title>
				<v-img
						:src="require('@/assets/img/cronaportal-side-white.png')"
						:lazy-src="require('@/assets/img/cronaportal-side-white.png')"
						contain
						width="200"
				/>
			</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-app-bar>

		<v-card class="fill-height">
			<v-card-text class="fill-height">
				<v-container fluid class="fill-height">
					<v-row no-gutters align="center" justify="center">
						<v-col cols="12" class="text-center">
							<v-icon color="accent" :size="100">mdi-lock-alert</v-icon>
							<Title bold>{{$t('InactivityDialog.Description1')}}</Title>
							<Title small>{{$t('InactivityDialog.Description2')}}</Title>
							<Title small>{{$t('InactivityDialog.Description3')}}</Title>

							<v-btn color="primary" depressed class="mt-4" @click="visible = false" autofocus>{{$t('Agree')}}</v-btn>
						</v-col>

						<v-col cols="12" class="text-center mt-5 pt-5">
							<Title bold>{{$t('InactivityDialog.Description4')}}</Title>
							<Title small>{{$t('InactivityDialog.Description5')}}</Title>

							<img
									class="mt-2"
									:src="require('@/assets/img/preferences.png')"
									:lazy-src="require('@/assets/img/preferences.png')"
									width="253"
							/>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Dialog from './Dialog.vue';
import Title  from './typography/Title.vue';

export default {
	name: 'LoggedOutDueToInactivityDialog',

	data: () => ({
		visible: false,
	}),

	methods: {
		show() {
			this.visible = true;
		},
	},

	components: {Title, Dialog},
}
</script>

<style scoped>

</style>
