var render = function render(){var _vm=this,_c=_vm._self._c;return _c('h4',_vm._g({staticClass:"font-weight-light",class:{
		'text-center': _vm.centered,
		'display-1': _vm.large && !_vm.small,
		title: !_vm.large && !_vm.small,
		'font-weight-medium': _vm.bold,
		'subtitle-1': _vm.small,
		'text-truncate': _vm.truncateText,
		'title-text-color': !(_vm.bold || _vm.$vuetify.theme.dark),
	},style:({'line-height': '1.4 !important'})},_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }