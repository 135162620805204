/**
 * Created by simon on 2019-11-25.
 */

import {forEach}    from 'lodash';
import store        from '../store/index.js';
import LocalStorage from '../lib/LocalStorage.js';

const sharedViewFilters = {};

forEach(store.state.SharedViewFilters, (v, key) => {
	Object.defineProperty(sharedViewFilters, key, {
		get() {
			return store.state.SharedViewFilters[key];
		},

		set(value) {
			if(key === 'dateFilter' && store.state.SharedViewFilters.dateFilter.Locked) {
				return;
			}
			if(key === 'dateFilter') { //If key changed is dateFilter (AS AN OBJECT) then Lock values (From DatePickerRange)
				value.Locked = true;
			}

			store.state.SharedViewFilters[key] = value;

			if(['showAutoCalc', 'showKonterat'].includes(key)) {
				LocalStorage.set(key, value);
			}

			if(key === 'selectedEmployee') {
				store.dispatch('loadSchemaInformationForSelectedEmployee');
			}
		},
	});
});

export default sharedViewFilters;
