<template>
	<Dialog
					ref="dialog"
					v-bind="ConfirmDialog"
					:title="ConfirmDialog.title || ''"
					@hide="hideConfirmDialog"
					:persistent="ConfirmDialog.persistent"
	>
		<div v-if="ConfirmDialog.text" v-html="ConfirmDialog.text" class="mb-2"></div>
		<slot v-else class="mb-2"></slot>

		<template v-slot:buttons="{}">
			<v-btn v-if="ConfirmDialog.showCancel !== false" :color="ConfirmDialog.cancelColor || 'secondary'" class="white--text" @click="onCancel">{{ConfirmDialog.cancelText}}</v-btn>
			<v-spacer></v-spacer>
			<v-btn v-if="ConfirmDialog.showSecondaryConfirm === true" :color="ConfirmDialog.secondaryConfirmColor || 'primary'" class="white--text" @click="onSecondaryConfirm">{{ConfirmDialog.secondaryConfirmText}}</v-btn>
			<v-btn v-if="ConfirmDialog.showConfirm !== false" :color="ConfirmDialog.confirmColor || 'primary'" ref="confirmBtn" class="white--text" @click="onConfirm">{{ConfirmDialog.confirmText}}</v-btn>
		</template>

	</Dialog>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import Dialog                 from './Dialog.vue';

export default {
	name:       'AppConfirmDialog',
	components: {Dialog},

	computed: {
		...mapState(['ConfirmDialog']),
	},

	watch: {
		'ConfirmDialog.visible'(value) {
			if(value === true) {
				this.$refs.dialog.show();

				if(this.ConfirmDialog.autoFocusConfirm) {
					this.$nextTick(() => {
						setTimeout(() => {
							this.$refs.confirmBtn.$el.focus();
						}, 1);
					});
				}
			} else {
				this.$refs.dialog.hide();
			}
		},
	},

	methods: {
		...mapActions(['confirmDialog', 'hideConfirmDialog']),

		onConfirm() {
			this.hideConfirmDialog();

			if(this.ConfirmDialog.onConfirm) {
				this.ConfirmDialog.onConfirm();
			}
		},

		onSecondaryConfirm() {
			this.hideConfirmDialog();

			if(this.ConfirmDialog.onSecondaryConfirm) {
				this.ConfirmDialog.onSecondaryConfirm();
			}
		},

		onCancel() {
			this.hideConfirmDialog();

			if(this.ConfirmDialog.onCancel) {
				this.ConfirmDialog.onCancel();
			}
		},
	},
}
</script>

<style scoped>

</style>
