<template>
	<v-form ref="form">
		<v-card-text>
			<InputField placeholder=" " :error="invalidLogin" :disabled="showSMSCodeField" :model="model" name="Login" autofocus prepend-icon="mdi-account" ref="Login" :counter="50"/>
			<PasswordField placeholder=" " :error="invalidLogin" :disabled="showSMSCodeField" :model="model" name="Password" ref="Password" prepend-icon="mdi-lock"/>
			<InputField placeholder=" " autofocus :error="invalidVerificationCode" v-if="showSMSCodeField" :model="model" name="SMSCode" ref="SMSCode" prepend-icon="mdi-cellphone"/>
			<v-alert
							v-if="showSMSCodeField"
							border="top"
							colored-border
							type="warning"
							elevation="2"
							color="accent"
							class="caption"
							dense
			>
				{{$t('Auth.SentVerificationCode')}}
			</v-alert>
			<v-btn style="width: 100%;" color="primary" ref="SaveBtn" @click="onSave" :loading="isAuthenticating">{{$t('Login')}}</v-btn>
			<v-btn class="mt-1" v-if="showSMSCodeField" style="width: 100%;" color="secondary" ref="SaveBtn" @click="showSMSCodeField = false" :disabled="isAuthenticating">{{$t('Cancel')}}</v-btn>
		</v-card-text>
		<v-card-actions class="pa-0">
			<v-btn :color="$vuetify.theme.dark ? 'accent' : 'secondary'" text @click="onClickForgotPassword">{{$t('ForgotPassword')}}</v-btn>
		</v-card-actions>
	</v-form>
</template>

<script>
import {mapActions}  from 'vuex';
import InputField    from '../../form/InputField.vue';
import PasswordField from '../../form/PasswordField.vue';
import LocalStorage  from '../../../lib/LocalStorage.js';
import Login         from '../../../models/Login.js';

export default {
	name: 'UsernamePassword',

	components: {
		InputField,
		PasswordField,
	},

	data: () => ({
		model: new Login({
			Login:    LocalStorage.get('Login'),
			Password: '',
		}),
		tabOrder: [
			'Login',
			'Password',
			'SaveBtn',
		],
		invalidLogin:            false,
		invalidVerificationCode: false,
		isAuthenticating:        false,
		showSMSCodeField:        false,
	}),

	methods: {
		...mapActions(['clearSnackbar', 'setAuthenticated', 'setupWebSocketClient']),

		onClickForgotPassword() {
			this.$openNewTab(process.env.VUE_APP_RESET_PASSWORD_LINK, {skipBaseURI: true});
		},

		onSave() {
			this.invalidLogin = false;
			this.clearSnackbar();

			if(!this.$validateForm()) {
				return;
			}

			this.$tryCatch({
				task: async () => {
					this.isAuthenticating = true;

					const {requestSMSCode, UserAccount, UserAppSettings, FtgInfo, SalaryYears, Auth, HasLicensOption, HasWebLon, HasPlus} = await this.$http.post('/authenticate', {
						Login:    this.model.Login,
						Password: this.model.Password,
						SMSCode:  this.model.SMSCode,
					});

					if(requestSMSCode) {
						this.showSMSCodeField = true;
						
						return;
					}

					LocalStorage.set('Login', UserAccount.Login);
					LocalStorage.set('LoginMethod', 1);

					this.setAuthenticated({UserAccount, UserAppSettings, SalaryYears, FtgInfo, Auth, HasLicensOption, HasWebLon, HasPlus});
				},
				error: (error) => {
					this.invalidVerificationCode = false;
					this.invalidLogin = false;

					if(error.DVCode === 40100 || error.DVCode === 40400) {
						if(this.showSMSCodeField) {
							this.invalidVerificationCode = true;
						} else {
							this.invalidLogin = true;
						}
					}
				},
				finally: () => {
					this.isAuthenticating = false;
				},
			});
		},
	},
}
</script>

<style scoped>

</style>
