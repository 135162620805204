<template>
	<div style="display: inline-block;">
		<ReceiptScanDialog ref="receiptScanDialog" @submit="onReceiptScanSubmitted"/>

		<input @click="reset" type="file" :multiple="false" ref="file" style="display: none;" :disabled="disabled" @change="onSelectFile">
		<div style="display: inline-block;" @click="open">
			<slot name="activator"></slot>

			<div v-if="file" style="display: inline-block;">
				<v-btn v-if="showRemoveSelectedBtn" :class="{'ml-1': $vuetify.breakpoint.mdAndUp}" @click.stop.prevent="reset" color="secondary" outlined small text>
					<v-icon left>mdi-close</v-icon> {{ allowScan ? $t('FileUpload.RemoveSelectedReceipt') : $t('FileUpload.RemoveSelectedFile') }}
				</v-btn>
				<v-btn class="ml-1" v-if="allowScan && SCAN_RECEIPTS_ENABLED && !getUseAutoScan()" @click.stop.prevent="$refs.receiptScanDialog.scan(file)" color="secondary" outlined small text>
					<v-icon left>mdi-magnify-scan</v-icon> {{ $t('Receipts.Scan') }}
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState}        from 'vuex';
import Tooltip           from '../Tooltip.vue';
import ReceiptScanDialog from '../ReceiptScanDialog.vue';
import LocalStorage      from '../../lib/LocalStorage.js';

export default {
	name:       'FileUpload',
	components: {ReceiptScanDialog, Tooltip},
	props:      {
		disabled: {
			type:    Boolean,
			default: false,
		},

		allowScan: {
			type:    Boolean,
			default: false,
		},

		showRemoveSelectedBtn: {
			type:    Boolean,
			default: true,
		},
	},

	data: () => ({
		file: null,
	}),

	methods: {
		getUseAutoScan() {
			return LocalStorage.get('AUTO_SCAN_FILES', true);
		},

		onReceiptScanSubmitted(data) {
			this.$emit('scanned', data);
		},

		open() {
			this.$refs.file.click();
		},

		reset() {
			this.file = null;
			this.$refs.file.value = null;
			this.$emit('input', this.file);
			this.$emit('change', this.file);
		},

		onFocus() {
			if (!this.disabled) {
				this.$refs.fileInput.click();
			}
		},

		onSelectFile($event) {
			const files = $event.target.files || $event.dataTransfer.files;

			if (files && files.length === 1) {
				const file = files[0];

				const reader = new FileReader();

				reader.readAsDataURL(file);

				reader.onload = () => {
					const fileContentBase64 = reader.result;
					const fileName          = file.name;
					const parts             = fileName.split('.').reverse();
					const Format            = parts.shift();
					const Name              = parts.reverse().join('.');

					this.file = {
						name:            Name,
						size:            file.size,
						extension:       Format,
						content:         fileContentBase64.split(';base64,')[1],
						srcDataContent:  fileContentBase64,
						type:            file.type,
						originalFileSrc: file,
					};

					this.$emit('input', this.file);
					this.$emit('change', this.file);

					if(this.SCAN_RECEIPTS_ENABLED && this.allowScan && this.getUseAutoScan()) {
						this.$refs.receiptScanDialog.scan(this.file);
					}
				};

				reader.onerror = (error) => {
					this.$emit('error', error);
				};
			} else {
				this.file = null;
			}
		},
	},

	computed: {
		...mapState(['UserAccount', 'HasPlus']),

		SCAN_RECEIPTS_ENABLED() {
			return this.HasPlus;
		},
	},
}
</script>

<style scoped>

</style>
