<template>
	<div>
		<v-menu :nudge-top="-33" v-model="menuOpen" :min-width="minWidth" :close-on-content-click="!isMultiple" role="none">
			<template v-slot:activator="{}">
				<v-text-field
					ref="field"
					v-bind="$attrs"
					@click="toggleMenu"
					@keydown.down.stop.prevent="onArrowKeyDown($event)"
					@keydown.up.stop.prevent="onArrowKeyUp($event)"
					:value="displayText"
					@keyup.native="onKeyUp($event)"
					@click:clear="emitUpdatedValue(null)"
					@click:append-outer="$emit('click:append-outer', $event)"
					color="accent"
					:placeholder="selectedItemDisplayText || $attrs.placeholder"
					autocomplete="off"
					:name="name"
					:data-tabordername="tabOrderName || name"
					persistent-placeholder
					role="combobox"
					aria-haspopup="true"
					:aria-expanded="!!menuOpen"
				>
					<template slot="append">
						<slot name="append" v-bind="{value, text: selectedItemDisplayText}"></slot>
						<v-icon :disabled="$attrs.disabled === true" @click="toggleMenu" tabindex="-1">{{menuOpen ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
					</template>

					<template v-if="useArrowNavigation" slot="append-outer">
						<v-btn
							small
							icon
							:disabled="isNil(value) || items.length < 2 || getValue(value) === getValue(items[items.length - 1]) || $attrs.loading"
							@click="onArrowNext"
							:aria-label="nextAriaLabel || $t('Next')"
						>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</template>

					<template v-if="useArrowNavigation" slot="prepend">
						<v-btn
							small
							icon
							:disabled="isNil(value) || items.length < 2 || getValue(value) === getValue(items[0]) || $attrs.loading"
							@click="onArrowPrev"
							:aria-label="previousAriaLabel || $t('Previous')"
						>
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
					</template>

					<template slot="prepend-inner">
						<slot name="prepend-inner" v-bind="{value}"></slot>
					</template>
				</v-text-field>
			</template>
			<v-lazy>
				<v-card ref="menuContent" outlined :height="filteredItems.length > 5 ? 200 : 'auto'" class="mt-0 pt-0">
					<v-virtual-scroll v-show="filteredItems.length > 0" ref="virtualScroll" :items="filteredItems" :item-height="itemHeight.toString()" class="mt-0 pt-0" :bench="10">
						<template v-slot:default="{ item, index }">
							<v-divider v-if="item.divideBefore === true"/>
							<v-list-item
								:ref="`listItem_${index}`"
								:key="`listItem_${index}`"
								@click="onMouseSelectItem(item)"
								:class="{
												'font-weight-bold': isMultiple ? false : getValue(item) === getValue(value),
												'v-list-item--active': isMultiple ? value.includes(getValue(item)) : index === listItemIndex
											}"
							>
								<v-list-item-action v-if="isMultiple" class="mt-1 mb-0">
									<SimpleCheckbox :value="value.includes(getValue(item))" :dense="false" hide-details/>
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>
										<slot name="itemtitle" v-bind="{item, text: getDisplayText(item)}">{{getDisplayText(item)}}</slot>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-divider v-if="item.divideAfter === true"/>
						</template>
					</v-virtual-scroll>

					<v-list v-show="filteredItems.length <= 0" dense>
						<v-list-item>
							<v-list-item-content>
								{{$t('NoResultFound')}}
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-lazy>
		</v-menu>
	</div>
</template>

<script>
import {filter, trim, isObject, has, isMatch, cloneDeep, isNil} from 'lodash';
import SimpleCheckbox                                           from './SimpleCheckbox.vue';

export default {
	name:       'InfiniteSelect',
	components: {SimpleCheckbox},

	data: () => ({
		search:                  '',
		menuOpen:                false,
		activeItemAsDisplayText: false,
		listItemIndex:           0,
		itemHeight:              48,
	}),

	watch: {
		menuOpen(newValue) {
			if(newValue === false) {
				this.search = '';
				this.activeItemAsDisplayText = false;
			} else if(newValue === true) {
				this.setListItemIndex();
				this.$nextTick(() => {
					this.scrollToCurrent();
				});
			}
		},

		items: {
			handler() {
				this.setListItemIndex();
			},
			deep: true,
		},

		value: {
			handler() {
				this.setListItemIndex();
			},
			deep: true,
		},

		search() {
			if(this.search) {
				this.listItemIndex = 0;
			} else {
				this.setListItemIndex();
			}
		},
	},

	mounted() {
		window.addEventListener('focusin', this.activeElementChanged);

		this.setListItemIndex();

		const $ref = this.$refs.field || this.$refs[`${this.tabOrderNamePrefix}${this.name}`];
		const originalOnKeyDown = $ref.onKeyDown;

		$ref.onKeyDown = (event) =>  {
			this.lastOnKeyDownEvent = event;

			return event.keyCode === 13 || event.keyCode === 9 ? this.onAutoSelectOnEnter(event) : originalOnKeyDown(event);
		};
	},

	beforeDestroy() {
		window.removeEventListener('focusin', this.activeElementChanged);
	},

	methods: {
		activeElementChanged({target}) {
			const {field, menuContent} = this.$refs;
			if(!field?.$el?.contains(target) && !menuContent?.$el?.contains(target)) {
				this.menuOpen = false;
			}
		},

		isNil(value) {
			return isNil(value);
		},

		onAutoSelectOnEnter(event) {
			const isTab = event.keyCode === 9;

			if(this.isMultiple) {
				if(isTab) {
					this.menuOpen = false;
				}

				return this.runTabOrderEvent(event);
			}

			const search = this.search.trim();

			const directMatch = search
				? this.items.find((item) => (this.getValue(item) || '').trim() === search)
				: null;

			if(directMatch) {
				this.emitUpdatedValue(directMatch);
			}

			const didNavigate = this.checkForTabOrderEvent(event, !directMatch) === false;

			if(didNavigate && isTab) {
				this.menuOpen = false;
			}
			if(directMatch) {
				event.preventDefault();
				event.stopPropagation();

				return false;
			}
		},

		onMouseSelectItem(value) {
			if(this.isMultiple) {
				const val   = this.getValue(value);
				const index = this.value.findIndex((iterationValue) => this.returnObject ? isMatch(val, iterationValue) : val === iterationValue);
				const list  = this.value.map((item) => cloneDeep(item));

				if(index >= 0) {
					list.splice(index, 1);
				} else {
					list.push(val);
				}

				this.emitUpdatedValue(list);

				return;
			}
			this.emitUpdatedValue(value);
			this.focus();
		},

		emitUpdatedValue(value) {
			if(this.isMultiple) {
				if(value === null) {
					value = [];
				}
			} else {
				value = value === null || this.returnObject
					? value
					: this.getValue(value);

				if(value === this.getValue(this.value)) { //Not changed
					return;
				}
			}

			this.$emit('input', value);
			this.$emit('change', value);
		},

		onArrowNext() {
			if(this.items[this.listItemIndex + 1]) {
				this.emitUpdatedValue(this.items[this.listItemIndex + 1]);
			}
		},

		onArrowPrev() {
			if(this.items[this.listItemIndex - 1]) {
				this.emitUpdatedValue(this.items[this.listItemIndex - 1]);
			}
		},

		scrollToCurrent() {
			clearInterval(this.scrollToCurrentInterval);

			this.scrollToCurrentInterval = setInterval(() => {
				if(this.$refs.virtualScroll && this.$refs.virtualScroll.$el) {
					clearInterval(this.scrollToCurrentInterval);
					this.$refs.virtualScroll.$el.scrollTop = Math.max(this.itemHeight * this.listItemIndex, 0);
					this.$refs.virtualScroll.onScroll();
				}
			}, 50);
		},

		setListItemIndex() {
			let index = 0;

			if(this.isMultiple) {
				if(this.value.length > 0) {
					const val = this.getValue(this.value[0]);

					index = this.items.findIndex((item) => this.getValue(item) === val);
				}
			} else {
				index = this.items.findIndex((item) => this.getValue(item) === this.getValue(this.value));
			}

			this.listItemIndex = Math.max(index, 0);
		},

		setSearch() {
			setTimeout(() => {
				this.search = this.$refs.field.$refs.input.value;
			}, 1);
		},

		checkForTabOrderEvent($event, autoSelect = true) {
			const isEnter = $event && $event.keyCode === 13;

			if(autoSelect && isEnter && this.filteredItems[this.listItemIndex]) {
				this.emitUpdatedValue(this.filteredItems[this.listItemIndex]);
			}

			if(isEnter) {
				this.search   = '';
				this.menuOpen = false;
			}

			const didNavigate = this.$checkForTabOrderEvent($event);

			if(didNavigate) {
				$event.preventDefault();
				$event.stopPropagation();

				return false;
			}
		},

		onKeyUp($event) {
			const isEnter = $event && $event.keyCode === 13;

			if(!isEnter && !this.$attrs.loading) {
				if(!this.menuOpen) {
					this.menuOpen = true;
				}

				if($event.keyCode === 40 || $event.keyCode === 38) {
					this.activeItemAsDisplayText = true;
				} else {
					this.activeItemAsDisplayText = false;
					this.setSearch();
				}
			}
		},

		onArrowKeyDown() {
			this.handleArrowNavigation(1);
		},

		onArrowKeyUp() {
			this.handleArrowNavigation(-1);
		},

		handleArrowNavigation(param) {
			const newListItemIndex = this.listItemIndex + param;

			if(newListItemIndex < 0 || newListItemIndex > this.filteredItems.length - 1) {
				return;
			}

			this.listItemIndex = newListItemIndex;

			setTimeout(() => {
				this.scrollToCurrent();
			}, 1);
		},

		toggleMenu() {
			if(this.$attrs.disabled) {
				return;
			}

			this.menuOpen = !this.menuOpen;
		},

		getValue(item) {
			if(!isObject(item)) {
				return item;
			}

			const itemValue = this.itemValue;

			if('function' === typeof itemValue) {
				return itemValue(item);
			}

			return item[itemValue];
		},

		getDisplayText(item) {
			const itemText = this.itemText;

			if('function' === typeof itemText) {
				return itemText(item);
			}

			return 'number' === typeof item[itemText] ? item[itemText].toString() : item[itemText];
		},

		focus(dontOpenOnFocus = false) {
			this.$refs.field.focus();

			if(this.openOnFocus && dontOpenOnFocus !== true) {
				this.menuOpen = true;
			}
		},

		blur() {
			this.$refs.field.blur();
			this.menuOpen = false;
		},
	},

	computed: {
		isMultiple() {
			return has(this.$attrs, 'multiple')
		},

		displayText() {
			if(this.activeItemAsDisplayText && this.listItemIndex >= 0 && this.listItemIndex < this.items.length) {
				return this.getDisplayText(this.items[this.listItemIndex]);
			} else if(this.menuOpen) {
				return this.search;
			}
			
			return this.selectedItemDisplayText;
		},

		selectedItemDisplayText() {
			if(this.isMultiple) {
				const texts = [];

				if(this.value.length > this.maxSelectionsBeforeStUnitUsed) {
					texts.push(`${this.value.length} st`)
				} else {
					for(const selectedItem of this.value) {
						const match = this.items.find((item) => this.getValue(item) === this.getValue(selectedItem));

						if(match) {
							texts.push(this.getDisplayText(match));
						}
					}
				}

				return texts.join(', ');
			}
			if(this.value || this.value === '' || this.value === 0 || this.value === false) {
				const match = this.items.find((item) => this.getValue(item) === this.getValue(this.value));

				if(match) {
					return this.getDisplayText(match);
				}
			}

			return '';
		},

		filteredItems() {
			const search = trim(this.search);

			if(!search) {
				return this.items;
			}

			return filter(this.items, (item) => this.getDisplayText(item).toLowerCase().includes(search.toLowerCase()))
		},
	},

	props: {
		items: {
			type:     Array,
			required: true,
		},

		minWidth: {
			type:    Number,
			default: 0,
		},

		itemValue: {
			default: 'value',
		},

		itemText: {
			default: 'text',
		},

		value: {},

		useArrowNavigation: {
			type:    Boolean,
			default: false,
		},

		previousAriaLabel: {
			type:    String,
			default: '',
		},

		nextAriaLabel: {
			type:    String,
			default: '',
		},

		returnObject: {
			type:    Boolean,
			default: false,
		},

		name: {
			type:     String,
			required: true,
		},

		tabOrderName: {
			type:     String,
			required: false,
			default:  '',
		},

		openOnFocus: {
			type:    Boolean,
			default: true,
		},
		maxSelectionsBeforeStUnitUsed: {
			type:    Number,
			default: 3,
		},
	},
}
</script>

<style scoped>

</style>
