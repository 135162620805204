<template>
	<div></div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
	name: 'AppGlobalEventSourceListeners',

	mounted() {
		this.$registerEventSourceHandler('NEW_UNREAD_MESSAGE_RECEIVED', this.onNewMessageReceived);
		this.$registerEventSourceHandler('RESOURCE_UPDATED', this.onResourceUpdated);
		this.$registerEventSourceHandler('ANSTNRS_CHANGED', this.onAnstNrsChanged);
		this.$registerEventSourceHandler('BRUKARNRS_CHANGED', this.onBrukarNrsChanged);
	},

	methods: {
		...mapActions(['addUnreadMessagesCount', 'handleResourceUpdatedEvent', 'handleAnstNrsChanged', 'handleBrukarNrsChanged']),

		onNewMessageReceived() {
			this.addUnreadMessagesCount(1);
		},

		onResourceUpdated(data) {
			this.handleResourceUpdatedEvent(data);
		},

		onAnstNrsChanged(data) {
			this.handleAnstNrsChanged(data);
		},

		onBrukarNrsChanged(data) {
			this.handleBrukarNrsChanged(data);
		},
	},
}
</script>

<style scoped>
</style>
