<template>
	<Dialog ref="dialog" :title="$t('ChangePassword')" :persistent="isSaving">
		<v-form ref="form">
			<v-container fluid>
				<v-row dense>
					<v-col cols="12">
						<PasswordField :model="model" autofocus name="CurrentPassword" ref="CurrentPassword" persistent-hint :hint="$t('ChangePasswordDialog.CurrentPasswordHint')"/>
					</v-col>
					<v-col cols="12" class="mt-4">
						<PasswordField :model="model" autofocus name="NewPassword" ref="NewPassword"/>
						<v-btn v-if="supportsCrypto" depressed small color="primary" @click="generatePassword">{{$t('ChangePasswordDialog.GeneratePassword')}}</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-form>

		<template v-slot:buttons="{hide}">
			<v-btn color="secondary" @click="hide" :disabled="isSaving">{{$t('Cancel')}}</v-btn>
			<v-spacer></v-spacer>
			<v-btn color="primary" ref="SaveBtn" :loading="isSaving" @click="onSave">{{$t('Save')}}</v-btn>
		</template>
	</Dialog>
</template>

<script>
import BaseModel     from '../models/BaseModel.js';
import Validate      from '../lib/Validate.js';
import {$t}          from '../plugins/i18n.js';
import PasswordField from './form/PasswordField.vue';
import Dialog        from './Dialog.vue';

class ChangePasswordModel extends BaseModel {
	static get propertyMap() {
		return {
			CurrentPassword: {
				label:        $t('CurrentPassword'),
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.required,
					Validate.minLength(6),
				],
			},

			NewPassword: {
				label:        $t('NewPassword'),
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.required,
					Validate.minLength(6),
				],
			},
		};
	}
}

const GENERATED_PASSWORD_LENGTH = 20;
const GENERATED_PASSWORD_CHARACTERS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$';

export default {
	name: 'ChangePasswordDialog',

	data: () => ({
		isSaving: false,
		model:    new ChangePasswordModel(),
		tabOrder: [
			'CurrentPassword',
			'NewPassword',
			'SaveBtn',
		],
		supportsCrypto: !!crypto,
	}),

	methods: {
		show() {
			this.model = new ChangePasswordModel();
			this.$refs.dialog.show();

			this.$nextTick(() => {
				this.$refs.NewPassword.setShowPlain(false);
			});
		},

		generatePassword() {
			this.model.NewPassword = [...crypto.getRandomValues(new Uint32Array(GENERATED_PASSWORD_LENGTH))]
				.map((x) => GENERATED_PASSWORD_CHARACTERS[x % GENERATED_PASSWORD_CHARACTERS.length])
				.join('');

			this.$refs.NewPassword.setShowPlain(true);
		},

		onSave() {
			this.$tryCatch({
				task: async () => {
					this.isSaving = true;

					await this.$http.post('/changepassword', this.model);

					this.$msgSuccess(this.$t('ChangePasswordDialog.Saved'));
					this.$refs.dialog.hide();
				},

				finally: () => {
					this.isSaving = false;
				},
			});
		},
	},

	components: {PasswordField, Dialog},
}
</script>

<style scoped>

</style>
