<template>
	<h4 v-on="$listeners" :style="{'line-height': '1.4 !important'}" :class="{
		'text-center': centered,
		'display-1': large && !small,
		title: !large && !small,
		'font-weight-medium': bold,
		'subtitle-1': small,
		'text-truncate': truncateText,
		'title-text-color': !(bold || $vuetify.theme.dark),
	}
" class="font-weight-light">
		<slot></slot>
	</h4>
</template>

<script>
export default {
	name: 'Title',

	props: {
		centered: {
			type:    Boolean,
			default: false,
		},

		truncateText: {
			type:    Boolean,
			default: true,
		},

		bold: {
			type:    Boolean,
			default: false,
		},

		large: {
			type:    Boolean,
			default: false,
		},

		small: {
			type:    Boolean,
			default: false,
		},
	},
}
</script>

<style scoped>
	h4 {
		min-height: 21px;
	}
</style>
