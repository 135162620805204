<template>
	<v-list two-line>
		<v-subheader v-if="title">{{title}}</v-subheader>
		<template v-for="item in items">
			<v-list-item
							:key="item.FtgId"
							@click="$emit('click', item)"
			>
				<v-list-item-avatar>
					<CompanyTableAvatar :item="item" :tile="true" :canEdit="false"/>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>
						{{item.Namn}}
					</v-list-item-title>
					<v-list-item-subtitle>{{$formatter.formatPersNrOrgNrTo12(item.Orgnr, {dash: true, serialNr: false}).slice(2)}} - {{item.FtgId}}</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action>
					<slot name="action" v-bind="{item}">
						<Tooltip :text="item.isFavourite ? $t('UnmarkAsFavourite') : $t('MarkAsFavourite')">
							<v-btn icon text :color="item.isFavourite ? 'accent' : 'secondary'" @click.stop="toggleFavourite(item)"><v-icon>{{item.isFavourite ? 'mdi-star' : 'mdi-star-outline'}}</v-icon></v-btn>
						</Tooltip>
					</slot>
				</v-list-item-action>
			</v-list-item>
		</template>
	</v-list>
</template>

<script>
import _                  from 'lodash';
import LocalStorage       from '../lib/LocalStorage.js';
import Tooltip            from './Tooltip.vue';
import CompanyTableAvatar from './CompanyTableAvatar.vue';

export default {
	name: 'SelectCompanyList',

	props: {
		items: {
			type:     Array,
			required: true,
		},

		title: {
			type:    String,
			default: '',
		},
	},

	components: {
		CompanyTableAvatar,
		Tooltip,
	},

	methods: {
		toggleFavourite(item) {
			const {FtgId} = item;
			const favourites = LocalStorage.get('FavouriteCompanies') || [];

			if(_.includes(favourites, FtgId)) {
				LocalStorage.set('FavouriteCompanies', _.filter(favourites, (v) => v !== FtgId));
				item.isFavourite = false;
			} else {
				favourites.push(FtgId);
				LocalStorage.set('FavouriteCompanies', favourites);
				item.isFavourite = true;
			}

			return false;
		},

		getNamnInitials(item) {
			const {Namn, FtgId} = item;

			if(Namn) {
				const first = Namn.slice(0, 1);
				const second = ([...Namn.slice(1)].find((value) => value === value.toUpperCase()) || '');

				return [first.toUpperCase(), second.toUpperCase()].join('');
			}

			return FtgId.slice(0, 2).toUpperCase();
		},
	},
}
</script>

<style scoped>

</style>
