<template>
	<v-bottom-sheet
					v-if="visible"
					hide-overlay
					inset
					width="600px"
					:value="true"
					persistent
					:retain-focus="false"
					no-click-animation
	>
		<v-lazy>
			<v-sheet
			>
				<v-alert border="top" colored-border type="info" elevation="2" class="ma-0 pb-0">
					<v-row dense>
						<v-col cols="10">
							{{$t('AppNewVersion.InfoText1')}}
							{{$t('AppNewVersion.InfoText2')}}
						</v-col>

						<v-col cols="2" class="text-right">
							<v-progress-circular
											:rotate="-90"
											:size="50"
											:width="5"
											:value="(timeLeftS / 60) * 100"
											:color="progressColor"
							>
								{{timeLeftS}}
							</v-progress-circular>
						</v-col>

						<v-col cols="6" class="text-right">
							<v-btn depressed small color="primary" @click="doUpdate"><v-icon left>mdi-check</v-icon> {{$t('AppNewVersion.UpdateNow')}}</v-btn>
						</v-col>

						<v-col cols="6">
							<v-badge
											bordered
											color="accent"
											icon="mdi-lock"
											overlap
							>
								<template v-slot:badge>
									{{addMoreTimeCounter}}
								</template>
								<v-btn
												:disabled="addMoreTimeCounter <= 0"
												small
												class="white--text"
												color="secondary"
												depressed
												@click="onAddMoreTime"
								>
									<v-icon left>mdi-timer-sand</v-icon>
									{{$t('AppNewVersion.NeedMoreTime')}}
								</v-btn>
							</v-badge>
						</v-col>
					</v-row>
				</v-alert>
			</v-sheet>
		</v-lazy>
	</v-bottom-sheet>
</template>

<script>
import moment from 'moment';

export default {
	name: 'AppNewVersionReloadPageTimer',

	mounted() {
		this.$registerEventSourceHandler('APP_VERSION_UPDATED', this.onAppVersionUpdated);
	},

	data: () => ({
		visible:            false,
		endTime:            null,
		timeLeftText:       '',
		timeLeftS:          0,
		addMoreTimeCounter: 3,
	}),

	methods: {
		doUpdate() {
			window.location.reload(true);
		},

		onAddMoreTime() {
			if(this.addMoreTimeCounter <= 0) {
				return;
			}

			this.endTime.setMinutes(this.endTime.getMinutes() + 1);
			this.timeLeftText = this.getTimeLeft();
			this.addMoreTimeCounter--;
		},

		getTimeLeft() {
			const timeLeftS = (this.endTime - Date.now()) / 1000;
			const timeLeftM = Math.floor(timeLeftS / 60);

			this.timeLeftS = Math.round(timeLeftS);

			if(timeLeftM > 0) {
				return `${timeLeftM} ${timeLeftM > 1 ? this.$t('Units.Minutes') : this.$t('Units.Minute')}`;
			}

			return `${Math.round(timeLeftS)} ${timeLeftS > 1 ? this.$t('Units.Seconds') : this.$t('Units.Second')}`;
		},

		onAppVersionUpdated() {
			setTimeout(() => {
				this.visible = true;

				this.endTime = moment().add(3, 'minutes').toDate();
				this.timeLeftText = this.getTimeLeft();

				const interval = setInterval(() => {
					this.timeLeftText = this.getTimeLeft();

					if(new Date() >= this.endTime) {
						clearInterval(interval);
						this.doUpdate();
					}
				}, 1000);
			}, 10000);
		},
	},

	computed: {
		progressColor() {
			if(this.timeLeftS <= 30) {
				return 'error';
			}

			if(this.timeLeftS <= 60) {
				return 'accent'
			}

			return 'primary';
		},
	},
}
</script>

<style scoped>

</style>
