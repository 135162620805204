import Vue       from 'vue';
import vuetify   from './plugins/vuetify.js';
import App       from './App.vue';
import router    from './router/index.js';
import store     from './store/index.js';
import dv        from './plugins/dv.js';
import tabOrder  from './plugins/tabOrder.js';
import tryCatch  from './plugins/tryCatch.js';
import shortcuts from './plugins/shortcuts.js';
import {i18n}    from './plugins/i18n.js';

Vue.config.productionTip = false;

Vue.use(dv);
Vue.use(tabOrder);
Vue.use(tryCatch);
Vue.use(shortcuts);

new Vue({
	render: (h) => h(App),
	router,
	store,
	vuetify,
	i18n,
}).$mount('#app');
