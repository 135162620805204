<template>
	<div>
		<v-btn @click="onInstall" v-show="showBtn" text color="secondary" small><v-icon left>{{icon}}</v-icon> {{text}}</v-btn>

		<Dialog ref="installGuideDialog" no-padding :width="700">
			<p class="ml-4 mr-4 pt-3">
				{{isMobile ? $t('AddToHomeScreenBtn.Guide.MobileDescription') : $t('AddToHomeScreenBtn.Guide.DesktopDescription')}}
			</p>
			<v-list dense class="pt-0 pb-0">
				<v-list-item>
					<v-list-item-avatar><v-icon>mdi-numeric-1-circle</v-icon></v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>
							{{$t('AddToHomeScreenBtn.Guide.ClickTheButton')}}
							[
							<v-icon class="mb-1">{{showIOSExportVariant ? 'mdi-export-variant' : 'mdi-dots-vertical'}}</v-icon>
							]
							{{isMobile && isBrowserIOSSafari ? $t('AddToHomeScreenBtn.Guide.Toolbar') : $t('AddToHomeScreenBtn.Guide.UpperRightCorner')}}.
						</v-list-item-title>
						<v-list-item-subtitle></v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action></v-list-item-action>
				</v-list-item>
				<v-list-item>
					<v-list-item-avatar><v-icon>mdi-numeric-2-circle</v-icon></v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>
							{{$t('AddToHomeScreenBtn.Guide.ClickTheButton')}}
							[
							<template v-if="showIOSExportVariant">
								{{$t('AddToHomeScreenBtn.InstallToHomeScreen')}} <v-icon>mdi-plus-box-outline</v-icon>
							</template>
							<template v-else-if="isMobile">
								<v-icon>mdi-cellphone-arrow-down-variant</v-icon> {{$t('AddToHomeScreenBtn.InstallToHomeScreen')}}
							</template>
							<template v-else>
								<v-icon>mdi-cellphone-arrow-down-variant</v-icon> {{$t('AddToHomeScreenBtn.InstallAsApp')}}
							</template>
							]
							<br v-show="$vuetify.breakpoint.xsOnly"/>
							{{$t('AddToHomeScreenBtn.Guide.MenuThatPopsUp')}}.
						</v-list-item-title>
						<v-list-item-subtitle>
							<b>{{$t('AddToHomeScreenBtn.Guide.ScrollInfo')}}</b>
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action></v-list-item-action>
				</v-list-item>
			</v-list>

			<template v-slot:buttons="{hide}">
				<v-spacer></v-spacer>
				<v-btn color="secondary" depressed @click="hide">{{$t('Close')}}</v-btn>
				<v-spacer></v-spacer>
			</template>
		</Dialog>

<!--		<v-list dense>-->
<!--			<v-list-item v-for="(value, key) in debugInfo" :key="key">-->
<!--				<v-list-item-content>-->
<!--					<v-list-item-title>{{key}}</v-list-item-title>-->
<!--				</v-list-item-content>-->
<!--				<v-list-item-action>-->
<!--					<template v-if="value === true">-->
<!--						<v-icon color="success">mdi-check-circle</v-icon>-->
<!--					</template>-->
<!--					<template v-else-if="value === false || value === null">-->
<!--						<v-icon color="error">mdi-close-box</v-icon>-->
<!--					</template>-->
<!--					<template v-else>-->
<!--						{{value}}-->
<!--					</template>-->
<!--				</v-list-item-action>-->
<!--			</v-list-item>-->
<!--		</v-list>-->
	</div>
</template>

<script>
import Dialog from './Dialog.vue';

export default {
	name:       'AddToHomeScreenBtn',
	components: {Dialog},

	data: () => ({
		defferedPrompt: null,
	}),

	mounted() {
		window.addEventListener('beforeinstallprompt', (event) => {
			// event.preventDefault();
			this.deferredPrompt = event;
		});
	},
	
	methods: {
		onInstall() {
			if(this.deferredPrompt) {
				this.deferredPrompt.prompt();
			} else {
				this.$refs.installGuideDialog.show();
			}
		},
	},

	computed: {
		debugInfo() {
			const {
				isMobile,
				isDeviceIOS,
				isDeviceAndroid,
				isDesktopWindows,
				isDesktopEdge,
				isDesktopSafari,
				isDesktopMac,
				isDesktopChrome,
				isBrowserIOSSafari,
				isBrowserIOSChrome,
			} = this;

			return {
				userAgent: navigator.userAgent,
				isMobile,
				isDeviceIOS,
				isDeviceAndroid,
				isDesktopWindows,
				isDesktopEdge,
				isDesktopSafari,
				isDesktopMac,
				isDesktopChrome,
				isBrowserIOSSafari,
				isBrowserIOSChrome,
			};
		},

		showBtn() {
			if(this.isStandalone) {
				return false;
			}

			return this.isDeviceIOS ||
				this.isDeviceAndroid ||
				this.isDesktopWindows ||
				this.isDesktopChrome ||
				this.isDesktopEdge ||
				this.isDesktopSafari;
		},

		isMobile() {
			return this.isDeviceIOS || this.isDeviceAndroid;
		},

		icon() {
			return this.isMobile
				? 'mdi-cellphone-arrow-down-variant'
				: 'mdi-monitor-arrow-down-variant';
		},

		showIOSExportVariant() {
			const {isDeviceIOS, isDesktopSafari, isDesktopMac, isBrowserIOSChrome, isBrowserIOSSafari} = this;

			return isDeviceIOS || isDesktopSafari || isDesktopMac || isBrowserIOSChrome || isBrowserIOSSafari;
		},

		text() {
			return this.isMobile || this.showIOSExportVariant
				? this.$t('AddToHomeScreenBtn.InstallToHomeScreen')
				: this.$t('AddToHomeScreenBtn.InstallAsApp');
		},

		//Already installed?
		isStandalone() {
			return ('standalone' in window.navigator && window.navigator.standalone) ||
				window.matchMedia('(display-mode: standalone)').matches;
		},
	},
}
</script>

<style scoped>
::v-deep(#add-to-home-screen) {
	display: none;
}
</style>
