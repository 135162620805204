/**
 * Created by simon on 2019-03-22.
 */

import Vue             from 'vue';
import RobustWebSocket from 'robust-websocket';

const {VUE_APP_UTV, VUE_APP_API_URI} = process.env;

class WebSocketClient {
	constructor() {
		this.$eventBus = Vue.prototype.$eventBus;

		try {
			const wsURL = VUE_APP_UTV === 'true'
				? `ws://${VUE_APP_API_URI.replace('http://', '').replace('/api', '')}`
				: `wss://${window.location.host}`;

			this.buildId = null;

			this.socket = new RobustWebSocket(wsURL);

			this.socket.addEventListener('error', (error) => console.error(error));

			this.socket.addEventListener('disconnect', () => console.log('Disconnected'));

			this.socket.addEventListener('message', (event) => {
				try {
					const {data} = event;
					const {event: wsEvent, payload} = JSON.parse(data);

					if(wsEvent === 'api/v1') {
						const {event: payloadEvent} = payload;

						this.$eventBus.$emit(`WebSocketClient:${payloadEvent}`, payload.payload);
					} else if(wsEvent === 'lon/set-build-id') {
						this.setBuildID(payload.value);
					}
				} catch(error) {
					console.error(error);
				}
			});

			this.$eventBus.$on('WebSocketClient:UpdateBuildID', (buildID) => {
				this.setBuildID(buildID);
			});
		} catch(error) {
			console.error(error);
		}
	}

	close() {
		try {
			this.socket.close();
		} catch {}
	}
	setBuildID(value) {
		if(this.appVersionUpdatedTriggered) {
			return;
		}

		if(!this.buildId) {
			this.buildId = value;
		}

		if(this.buildId && this.buildId !== value) {
			this.$eventBus.$emit('WebSocketClient:APP_VERSION_UPDATED');

			this.buildId = value;
			this.appVersionUpdatedTriggered = true;

			try {
				if(this.socket.readyState === 1) {
					this.socket.close();
					this.socket.open();
				}
			} catch{}
		}
	}
}

export default WebSocketClient;
