import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

import Vue                      from 'vue';
import Vuetify, {VLayout, VBtn} from 'vuetify/lib/index.js';
import colors                   from 'vuetify/lib/util/colors.js'

import en           from 'vuetify/lib/locale/en.js'
import fr           from 'vuetify/lib/locale/fr.js'
import pl           from 'vuetify/lib/locale/pl.js'
import sv           from '../locale/vuetify_sv.js'
import LocalStorage from '../lib/LocalStorage.js';

Vue.use(Vuetify);

const vuetifyInstance = new Vuetify({
	icons: {
		iconfont: 'mdi',
	},
	lang: {
		locales: {sv, en, fr, pl},
		current: LocalStorage.get('Language', 'sv'),
	},
	components: {
		VLayout,
		VBtn,
	},
	theme: {
		dark:   LocalStorage.getDarkModePreference(),
		themes: {
			light: {
				primary:   '#455A64',
				secondary: '#607D8B',
				accent:    '#FF9800',
				error:     colors.red,
				warning:   colors.yellow.darken2,
				info:      colors.blue.darken2,
				success:   colors.green.darken1,
			},
			dark: {
				primary:   '#455A64',
				secondary: '#607D8B',
				accent:    '#FF9800',
				error:     colors.red,
				warning:   colors.yellow.darken2,
				info:      colors.blue.darken2,
				success:   colors.green.darken1,
			},
		},
	},
});

export default vuetifyInstance
