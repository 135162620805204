<template>
	<v-dialog
			title="Crona Portal"
			v-model="visible"
			v-bind="$attrs"
			v-on="$listeners"
			fullscreen
			no-click-animation
			:retain-focus="false"
	>
		<v-card class="fill-height" height="auto">
			<v-card-text class="fill-height">
					<v-row no-gutters align="center" justify="center">
						<v-col cols="12" class="text-center">
							<v-img
									:src="$vuetify.theme.dark ? require('@/assets/img/cronaportal-white.png') : require('@/assets/img/cronaportal-dark.png')"
									:lazy-src="$vuetify.theme.dark ? require('@/assets/img/cronaportal-white.png') : require('@/assets/img/cronaportal-dark.png')"
									contain
									:max-height="175"
									class="portal-image mb-5 mt-5"
							/>
							<Title :truncate-text="false" small>{{$t('LegacyPortalDialog.PortalDescription2')}}</Title>
							<Title :truncate-text="false" small>{{$t('LegacyPortalDialog.PortalDescription3')}}</Title>
							<Title :truncate-text="false" small>{{$t('LegacyPortalDialog.PortalDescription4')}}</Title>
							<v-btn color="primary" class="mt-2" depressed @click="visible = false">{{proceedBtnText}}</v-btn>
						</v-col>

						<v-col cols="12" class="text-center mt-5 pt-5">
							<Title :truncate-text="false" bold>App</Title>
							<Title :truncate-text="false" small>{{$t('LegacyPortalDialog.AppDescription1')}}</Title>
							<Title :truncate-text="false" class="mb-2" small>{{$t('LegacyPortalDialog.AppDescription2')}}</Title>
							<a href='https://play.google.com/store/apps/details?id=se.datavara.cronaportal&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img width="235" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
							<br/>
							<a href='https://apps.apple.com/se/app/crona-portal/id1557570009?ign-mpt=uo%3D4'><img width="200" src='https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg'/></a>
						</v-col>

						<v-col cols="12" class="text-center mt-5 pt-5">
							<Title :truncate-text="false" bold>{{$t('LegacyPortalDialog.LegacyPortalDescription1')}}</Title>
							<Title :truncate-text="false" small v-html="$t('LegacyPortalDialog.LegacyPortalDescription2', {enddate: legacyPortalEndDate})"></Title>
							<Title :truncate-text="false" small>{{$t('LegacyPortalDialog.LegacyPortalDescription3')}}</Title>

							<v-img
									class="mt-2"
									:src="require('@/assets/img/legacy-portal.png')"
									:lazy-src="require('@/assets/img/legacy-portal.png')"
									contain
									:max-height="90"
							/>
							<v-btn color="primary" class="mt-2" depressed small @click="onClickLegacyPortal">{{$t('LegacyPortalDialog.ToLegacyPortal')}} <v-icon right>mdi-open-in-new</v-icon></v-btn>
						</v-col>
					</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Title from './typography/Title.vue';

export default {
	name: 'LegacyPortalDialog',

	props: ['proceedBtnText'],

	data: () => ({
		visible: false,
	}),

	methods: {
		show() {
			this.visible = true;
		},

		onClickLegacyPortal() {
			this.$openNewTab(process.env.VUE_APP_LEGACY_PORTAL_LINK, {skipBaseURI: true});
			this.visible = false;
		},
	},

	computed: {
		legacyPortalEndDate() {
			return process.env.VUE_APP_LEGACY_PORTAL_END_DATE;
		},
	},

	components: {Title},
}
</script>

<style scoped>
.portal-image {
	margin: 0 auto;
}
</style>
