<template>
		<v-container class="text-center"> <!-- padding -->
			<v-icon size="200">mdi-alert-outline</v-icon>
			<h1 class="text-center headline">{{$t('NotFound.Title')}}</h1>
			<v-row dense justify="center"><!-- centrera knappen -->
				<v-btn text color="accent" @click="$router.back()">
					{{$t('NotFound.Back')}}
				</v-btn>
			</v-row>
		</v-container>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
