<template>
	<v-checkbox
					v-bind="$attrs"
					v-on="$listeners"
					color="accent"
					:name="name"
					:data-tabordername="tabOrderName || name"
					@keydown="$emit('keydown', $event)"
					@keydown.native="$checkForTabOrderEvent($event)"
					@keyup="$emit('keyup', $event)"
					ref="field"
					autocomplete="off"
					dense
					:input-value="value"
					@change="(value) => $emit('input', value)"
	>
		<template v-slot:label="">
			<slot name="label"></slot>
		</template>
	</v-checkbox>
</template>

<script>
export default {
	name: 'SimpleCheckbox',

	methods: {
		focus() {
			this.$refs.field.$refs.input.focus();
		},

		blur() {
			this.$refs.field.$refs.input.blur();
		},
	},

	props: ['name', 'value', 'tabOrderName'],
}
</script>

<style scoped>

</style>
