/**
 * Created by simon on 2020-01-28.
 */

import Vue          from 'vue'
import moment       from 'moment';
import VueI18n      from 'vue-i18n';
import LocalStorage from '../lib/LocalStorage.js';
import sv           from '../locale/sv.js'

Vue.use(VueI18n);


const loadedLanguages = ['sv'];

export const i18n = new VueI18n({
	locale:         'sv',
	fallbackLocale: 'sv',
	messages:       {},
});

i18n.setLocaleMessage('sv', sv);

export const $t = i18n.t.bind(i18n);

function setI18nLanguage (lang) {
	i18n.locale = lang
	LocalStorage.set('Language', lang);
	moment.locale(lang);
	
	return lang
}

export function loadLanguageAsync(lang) {
	// If the same language
	if (i18n.locale === lang) {
		return Promise.resolve(setI18nLanguage(lang))
	}

	// If the language was already loaded
	if (loadedLanguages.includes(lang)) {
		return Promise.resolve(setI18nLanguage(lang))
	}

	// If the language hasn't been loaded yet
	return import(/* webpackChunkName: "lang-[request]" */ `../locale/${lang}.js`).then(
		(messages) => {
			i18n.setLocaleMessage(lang, messages.default)
			loadedLanguages.push(lang)
			
			return setI18nLanguage(lang)
		}
	)
}

loadLanguageAsync(LocalStorage.get('Language', 'sv'));
