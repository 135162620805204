<template>
	<v-badge class="mt-2" v-if="count > 0" color="accent">
		<template v-slot:badge="">
			<span v-if="count > 0">{{count}}</span>
		</template>
	</v-badge>
</template>

<script>
export default {
	props: ['count'],

	name: 'NavigationNotificationCountBadge',
}
</script>

<style scoped>

</style>
