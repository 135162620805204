/**
 * Created by simon on 2020-01-28.
 */

import Validate from '../lib/Validate.js';

import {$t}      from '../plugins/i18n.js';
import BaseModel from './BaseModel.js';

class Login extends BaseModel {
	static get propertyMap() {
		return {
			Login: {
				label:        $t('Username'),
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.validLogin,
				],
			},
			Password: {
				label:        $t('Password'),
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.validPassword,
				],
			},

			SMSCode: {
				label:        $t('SMSVerificationCode'),
				type:         String,
				defaultValue: '',
				rules:        [
					Validate.required,
				],
			},
		};
	}
}

export default Login;
