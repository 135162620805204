<template>
	<div>
		<v-menu v-if="!dropdown" v-model="menuOpen" offset-y>
			<template v-slot:activator="{on}">
				<v-btn text v-on="on" :color="color" :class="classes" small>
					<v-icon left>mdi-earth</v-icon>
					{{selectedLanguageText}}
					<v-icon right>{{menuOpen ? 'mdi-menu-up' : 'mdi-menu-down'}}</v-icon>
				</v-btn>
			</template>

			<v-list dense >
				<v-list-item v-for="(item, index) in availableLanguages" :key="index" @click="setLanguage(item.value)" :input-value="item.value === $i18n.locale">
					<v-list-item-title>{{item.text}}</v-list-item-title>
					<v-list-item-action>
						<v-img v-if="countryFlagMap[item.value]" :src="`/countryflags/${countryFlagMap[item.value]}.png`" :lazy-src="`/countryflags/${countryFlagMap[item.value]}.png`" contain width="30"/>
					</v-list-item-action>
				</v-list-item>
			</v-list>
		</v-menu>

		<InfiniteSelect
			v-if="dropdown"
			:value="$i18n.locale"
			name="language"
			ref="language"
			:items="availableLanguages"
			item-value="value"
			item-text="text"
			:label="$t('LanguagePicker.Label')"
			@change="setLanguage"
			v-bind="$attrs"
		>
			<template v-slot:append="{value}">
				<v-img v-if="countryFlagMap[value]" :src="`/countryflags/${countryFlagMap[value]}.png`" :lazy-src="`/countryflags/${countryFlagMap[value]}.png`" contain width="30"/>
			</template>

			<template v-slot:itemtitle="{item: {value}, text}">
				<v-row no-gutters>
					<v-col cols="8" class="text-truncate">
						{{text}}
					</v-col>
					<v-col cols="4" class="text-right">
						<v-img v-if="countryFlagMap[value]" :src="`/countryflags/${countryFlagMap[value]}.png`" :lazy-src="`/countryflags/${countryFlagMap[value]}.png`" contain width="25" style="display: inline-block; vertical-align: middle"/>
					</v-col>
				</v-row>
			</template>
		</InfiniteSelect>
	</div>
</template>

<script>
import {loadLanguageAsync} from '../plugins/i18n.js';
import InfiniteSelect      from './InfiniteSelect.vue';

export default {
	name:       'LanguagePicker',
	components: {InfiniteSelect},
	props:      {
		dropdown: {
			type:    Boolean,
			default: false,
		},

		color: {
			type:    String,
			default: 'secondary',
		},
		classes: {
			type:    String,
			default: '',
		},
	},

	data: () => ({
		menuOpen:       false,
		countryFlagMap: {
			sv: 'se',
			en: 'us',
			fr: 'fr',
			pl: 'pl',
		},
	}),

	methods: {
		setLanguage(value) {
			loadLanguageAsync(value);
			this.$vuetify.lang.current = value;
			this.$eventBus.$emit('languageSelected', value);
		},
	},

	computed: {
		availableLanguages() {
			return [
				{value: 'sv', text: 'Svenska'},
				{value: 'en', text: 'English'},
				{value: 'fr', text: 'Français'},
				{value: 'pl', text: 'Polskie'},
			]
		},

		selectedLanguageText() {
			const selectedLanguage = this.availableLanguages.find((item) => item.value === this.$i18n.locale);

			return selectedLanguage ? selectedLanguage.text : '';
		},
	},
}
</script>

<style scoped>

</style>
