<template>
	<v-container fluid>
		<v-row dense align="center" justify="center"  >
			<v-col cols="12" sm="10" md="8" lg="5">
				<v-img
						:src="$vuetify.theme.dark ? require('@/assets/img/cronaportal-white.png') : require('@/assets/img/cronaportal-dark.png')"
						:lazy-src="$vuetify.theme.dark ? require('@/assets/img/cronaportal-white.png') : require('@/assets/img/cronaportal-dark.png')"
						:max-height="175"
						contain
						class="mb-5 mt-5"
				/>
				<v-card outlined>
					<v-tabs v-model="tab" slider-color="accent" :color="$vuetify.theme.dark ? 'accent' : 'primary'" grow>
						<template v-for="(item, index) in tabs">
							<v-tab :key="index">{{item.label}}</v-tab>
						</template>

						<template v-for="(item, index) in tabs">
							<v-tab-item :value="index">
								<v-divider></v-divider>
								<component class="pt-3" v-bind:is="item.component" v-bind="item.params"/>
							</v-tab-item>
						</template>

					</v-tabs>
				</v-card>
			</v-col>
			<v-col cols="12" class="pa-0">

			</v-col>
			<v-col cols="6" class="text-center">
				<AddToHomeScreenBtn/>
			</v-col>
			<v-col cols="12" class="pa-0">

			</v-col>
			<v-col cols="6" class="text-center">
				<LanguagePicker/>
			</v-col>
			<v-col cols="12"></v-col>
			<v-col cols="12" md="6"  class="text-center" v-if="false">
				<LegacyPortalDialog ref="legacyPortalDialog" :proceed-btn-text="$t('Auth.ProceedToLogin')"/>
				<v-btn color="accent" depressed small @click="$refs.legacyPortalDialog.show()"><v-icon left>mdi-help-circle-outline</v-icon> {{$t('WhyDoesPortalLookDifferent')}}</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import LocalStorage       from '../../lib/LocalStorage.js';
import LanguagePicker     from '../LanguagePicker.vue';
import LegacyPortalDialog from '../LegacyPortalDialog.vue';
import AddToHomeScreenBtn from '../AddToHomeScreenBtn.vue';
import BankIDLogin        from './Forms/BankIDLogin.vue';
import UsernamePassword   from './Forms/UsernamePassword.vue';

export default {
	name: 'Login',
	data: () => ({
		tab: parseInt(LocalStorage.get('LoginMethod'), 10) || null,
	}),

	computed: {
		tabs() {
			return [
				{label: this.$t('Auth.RequiresBankID.Title'), component: 'BankIDLogin', params: {}},
				{label: this.$t('Username'), component: 'UsernamePassword', params: {}},
			];
		},
	},

	components: {
		AddToHomeScreenBtn,
		LegacyPortalDialog,
		LanguagePicker,
		UsernamePassword,
		BankIDLogin,
	},
}
</script>

<style scoped>
	::v-deep(.v-slide-group__prev, .v-slide-group__next) {
		display: none !important;
	}
</style>
