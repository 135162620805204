import {$t}      from '../plugins/i18n.js';
import BaseModel from './BaseModel.js';

class KalFilterModel extends BaseModel {
	static get propertyMap() {
		return {
			TidKod: {
				label:        $t('TidKod'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			LonKod: {
				label:        $t('KalArt.LonKod'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			Brukare: {
				label:        $t('Brukare'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			ResEnhet1: {
				label:        $t('ResEnh1'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			ResEnhet2: {
				label:        $t('ResEnh2'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			ResEnhet3: {
				label:        $t('ResEnh3'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			ResEnhet4: {
				label:        $t('ResEnh4'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			ResEnhet5: {
				label:        $t('ResEnh5'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},

			ResEnhet6: {
				label:        $t('ResEnh6'),
				type:         Array,
				defaultValue: () => [],
				rules:        [],
			},
		};
	}
}

export default KalFilterModel;
