/**
 * Created by simon on 2018-09-12.
 */

import Vue                                                                                from 'vue';
import Vuex                                                                               from 'vuex';
import moment                                                                             from 'moment';
import IdleJs                                                                             from 'idle-js'
import {noop, keyBy, sortBy, find, filter, toNumber, isEmpty, first, keys, isPlainObject} from 'lodash';
import {$t}                                                                               from '../plugins/i18n.js';
import router                                                                             from '../router/index.js';
import HttpRequester                                                                      from '../lib/HttpRequester.js';
import WebSocketClient                                                                    from '../lib/WebSocketClient.js';
import LocalStorage                                                                       from '../lib/LocalStorage.js';
import SessionStorage                                                                     from '../lib/SessionStorage.js';
import KalFilterModel                                                                     from '../models/KalFilterModel.js';

Vue.use(Vuex);

const getDefaultState = () => ({
	IdleTracker:     null,
	NetworkOffline:  false,
	UserAppSettings: {
		DateFilterPeriodType: 'M',
		InitialDateFilter:    'CURRENT',
		PaginationPerPage:    25,
		InactivityTimer:      60,
	},
	UserAccount:   {},
	FtgInfo:       {},
	Authenticated: false,
	Auth:          {
		method: '',
		time:   null,
	},
	TidKoder:            [],
	LonKoder:            [],
	Varugrupper:         [],
	PerLista:            [],
	AvdelningLista:      [],
	BrukarLista:         [],
	BrukarAnsvLista:     [],
	UnreadMessagesCount: 0,
	PortalInfo:          {},
	BemUppgift:          [],
	Kompetenser:         [],
	Egenskaper:          [],
	ResultatEnheter:     {
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: [],
	},
	Holidays:           {}, //Indexed by date
	HolidayYearsLoaded: [],
	SalaryYears:        [],
	SalaryYear:         null,
	HasPlus:            false,
	HasWebLon:          false,
	Snackbar:           {
		title:     '',
		text:      '',
		color:     'error',
		visible:   false,
		timeout:   10000,
		multiline: false,
	},
	ConfirmDialog: {
		visible:              false,
		title:                '',
		text:                 '',
		cancelText:           $t('No'),
		confirmText:          $t('Yes'),
		secondaryConfirmText: '',
		onConfirm:            noop,
		onCancel:             noop,
		onSecondaryConfirm:   noop,
		showCancel:           true,
		showConfirm:          true,
		showSecondaryConfirm: false,
		autoFocusConfirm:     false,
	},
	HasLicensOption:             {},
	HasUserOption:               {},
	WebSocketClient:             null,
	NavigationNotificationCount: {},
	SharedViewFilters:           {
		showInactiveEmployeesInEmployeeSelector: LocalStorage.get('showInactiveEmployeesInEmployeeSelector', false),
		isLoadingPerLista:                       false,
		selectedEmployee:                        null,
		selectedCalendarDisplayMode:             'DATE',
		salaryPeriodsByAnstNr:                   {},
		showAutoCalc:                            LocalStorage.get('showAutoCalc', true),
		showKonterat:                            LocalStorage.get('showKonterat', false),
		bemanna:                                 {
			selectedBehov: null,
			calendarType:  LocalStorage.get('BemannaCalendarType', 'week'),
			activeDate:    moment().format('YYYY-MM-DD'),
			range:         [
				6,
				18,
			],
			firstHour: 6,
			lastHour:  18,
		},
		dateFilter: {
			Locked: false,
			From:   null,
			To:     null,
			contains(date) {
				if(this.From && this.To) {
					return moment(date).isBetween(this.From, this.To, undefined, '[]');
				}

				return false;
			},
			toQueryString(joiner = '|') {
				return [this.From.format('YYYY-MM-DD'), this.To.format('YYYY-MM-DD')].join(joiner);
			},
			toString() {
				const From = this.From.format('YYYY-MM-DD');
				const To = this.To.format('YYYY-MM-DD');

				if(From === To) {
					return From;
				}

				return [From, To].join(' - ');
			},
		},
		kalFilterModel: new KalFilterModel(),
	},
});

const setSnackbar = (context, {title = '', text = '', color = 'error', multiline = false, timeout = 10000, position = 'bottom', right = false, left = false} = {}, dispatch) => {
	const hasVisibleSnackbar = context.state.Snackbar.visible;

	dispatch('clearSnackbar');

	if(text) {
		if(timeout <= 10000 && text.length > 100) {
			timeout = 20000;
		}

		const newSnackbarData = {visible: true, title, text, color, multiline, timeout, position, right, left};

		if(hasVisibleSnackbar) {
			//Allow existing snackbar to disappear before showing next snackbar
			setTimeout(() => {
				context.state.Snackbar = newSnackbarData;
			}, 1);
		} else {
			context.state.Snackbar = newSnackbarData;
		}
	}
};

export default new Vuex.Store({
	state: getDefaultState(),

	actions: {
		setAuthenticated(context, {Auth, UserAccount, UserAppSettings, FtgInfo = null, SalaryYears = null, SessionSalaryYear = null, HasLicensOption = null, HasPlus = null, HasWebLon = null}) {
			context.state.UserAccount = UserAccount;
			context.state.UserAppSettings = UserAppSettings;

			const {UserOption = []} = UserAccount;

			context.state.HasUserOption = {};
			for(const item of UserOption) {
				context.state.HasUserOption[item.Option] = true;
			}

			if(HasLicensOption) {
				context.state.HasLicensOption = HasLicensOption;
			}

			if(HasPlus === false || HasPlus === true) {
				context.state.HasPlus = HasPlus;
			}

			if(HasWebLon === false || HasWebLon === true) {
				context.state.HasWebLon = HasWebLon;
			}

			clearContextCache(context, {
				clearSelectedEmployee: FtgInfo && context.state.FtgInfo && context.state.FtgInfo.FtgId && context.state.FtgInfo.FtgId !== FtgInfo.FtgId,
			});

			if(FtgInfo) {
				context.state.FtgInfo = FtgInfo;
				SessionStorage.set('FtgId', FtgInfo.FtgId);

				if(FtgInfo.FtgId) {
					this.dispatch('loadPortalInfo', {refreshCache: true, checkRouterAccess: true});
					this.dispatch('loadPerLista');
				}
			}

			if(SalaryYears && SalaryYears.length > 0) {
				context.state.SalaryYears = SalaryYears;

				if(SessionSalaryYear) {
					context.state.SalaryYear = SessionSalaryYear;
					SessionStorage.set('SalaryYear', SessionSalaryYear);
				} else {
					this.dispatch('setSalaryYear', {Year: filter(context.state.SalaryYears, (year) => year <= (new Date().getFullYear()))[0] || first(context.state.SalaryYears)});
				}
			}

			if(Auth) {
				if(Auth.time) {
					Auth.time = moment(Auth.time);
				}

				context.state.Authenticated = true;
				context.state.Auth = Auth;
			}

			this.dispatch('setupWebSocketClient');
			this.dispatch('createIdleTimer');

			if(router.currentRoute && router.currentRoute.name === 'Http404') {
				router.push({name: 'Dashboard'});
			}
		},

		createIdleTimer(context) {
			if(context.state.IdleTracker) {
				context.state.IdleTracker.stop();
				context.state.IdleTracker = null;
			}

			const idleTimer = context.state.UserAppSettings.InactivityTimer * 60000;

			if(idleTimer > 0) {
				context.state.IdleTracker = new IdleJs({
					idle:        idleTimer,
					events:      ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
					startAtIdle: false,
					onIdle() {
						context.state.IdleTracker.stop();
						context.state.IdleTracker = null;
						context.dispatch('logout');

						try {
							const {$children = []} = router.app;
							const [appView] = $children;

							appView.showInactivityDialog();
						} catch{}
					},
				});

				context.state.IdleTracker.start();
			}
		},

		addSalaryYear(context, Year) {
			context.state.SalaryYears.push(String(Year));
		},

		setUpdatedFtgInfo(context, FtgInfo) {
			context.state.FtgInfo = FtgInfo;

			const {checkRouterAccess} = FtgInfo;

			if(checkRouterAccess) {
				router.checkAccess(router.currentRoute, {autoNavigate: true});
			}
		},

		setSalaryYear(context, {Year}) {
			Year = toNumber(Year);

			if(Year === context.state.SalaryYear) {
				return;
			}

			// clearContextCache(context);

			//This automatically triggers a re-render of page as it is keyed by salaryyear (router-view)
			context.state.SalaryYear = Year;
			SessionStorage.set('SalaryYear', Year);
		},

		logout(context, {saveSnackbarState = false, sendKillSessionRequest = true} = {}) {
			if(context.state.IdleTracker) {
				context.state.IdleTracker.stop();
			}

			if(context.state.WebSocketClient) {
				context.state.WebSocketClient.close();
			}
			
			SessionStorage.clear();

			const defaultState = getDefaultState();

			//Preserve snackbar if any are visible, such as network error
			if(saveSnackbarState) {
				delete defaultState.Snackbar;
			}

			router.push({name: 'Dashboard'}, noop, noop);

			Object.assign(context.state, defaultState);

			if(sendKillSessionRequest) {
				HttpRequester.get('/logout');
			}
		},

		toggleNetworkOffline(context, toggle) {
			context.state.NetworkOffline = toggle;
		},

		setSnackbar(context, options = {}) {
			setSnackbar(context, options, this.dispatch);
		},

		clearSnackbar(context) {
			context.state.Snackbar.visible = false;
		},

		toggleShowInactiveEmployeesInEmployeeSelector(context) {
			context.state.SharedViewFilters.showInactiveEmployeesInEmployeeSelector = !context.state.SharedViewFilters.showInactiveEmployeesInEmployeeSelector;
			LocalStorage.set('showInactiveEmployeesInEmployeeSelector', context.state.SharedViewFilters.showInactiveEmployeesInEmployeeSelector);
			this.dispatch('loadPerLista', {refreshCache: true});
		},

		async loadTidKoder(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.TidKoder, refreshCache)) {
				context.state.TidKoder = await HttpRequester.get('/tidkoder');
				context.state.TidKoder.lastFetchedTime = new Date();
			}
		},

		async loadLonKoder(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.LonKoder, refreshCache)) {
				context.state.LonKoder = await HttpRequester.get('/LonKoder');
				context.state.LonKoder.lastFetchedTime = new Date();
			}
		},

		async loadVarugrupper(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.Varugrupper, refreshCache)) {
				context.state.Varugrupper = await HttpRequester.get('/varugrupper');
				context.state.Varugrupper.lastFetchedTime = new Date();
			}
		},

		async loadKontoReg(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.KontoReg, refreshCache)) {
				context.state.KontoReg = await HttpRequester.get('/kontoreg');
				context.state.KontoReg.lastFetchedTime = new Date();
			}
		},

		async loadBrukarLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.BrukarLista, refreshCache)) {
				if(context.state.FtgInfo && !context.state.FtgInfo.UseLSS) {
					context.state.BrukarLista = [];
					
					return;
				}
				context.state.BrukarLista = await HttpRequester.get('/brukarlista');
				context.state.BrukarLista.lastFetchedTime = new Date();
			}
		},

		async loadBrukarAnsvLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.BrukarAnsvLista, refreshCache)) {
				if(context.state.FtgInfo && !context.state.FtgInfo.UseLSS) {
					context.state.BrukarAnsvLista = [];
					
					return;
				}
				context.state.BrukarAnsvLista = await HttpRequester.get('/brukaransvlista');
				context.state.BrukarAnsvLista.lastFetchedTime = new Date();
			}
		},

		async loadBemUppgift(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.BemUppgift, refreshCache)) {
				context.state.BemUppgift = await HttpRequester.get('/bemanna/bemuppgift', {
					params: {
						Deleted: false,
					},
				});
				context.state.BemUppgift.lastFetchedTime = new Date();

				if(context.state.SharedViewFilters.bemanna.selectedBehov) {
					context.state.SharedViewFilters.bemanna.selectedBehov = context.state.BemUppgift.find((item) => item.Uppgift === context.state.SharedViewFilters.bemanna.selectedBehov.Uppgift);
				}

				if(!context.state.SharedViewFilters.bemanna.selectedBehov && context.state.BemUppgift.length > 0) {
					context.state.SharedViewFilters.bemanna.selectedBehov = context.state.BemUppgift[0];
				}
			}
		},

		async loadKompetenser(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.Kompetenser, refreshCache)) {
				context.state.Kompetenser = await HttpRequester.get('/kompetenser');
				context.state.Kompetenser.lastFetchedTime = new Date();
			}
		},

		async loadPerLista(context, {refreshCache = false} = {}) {
			const UserAccount = context.state.UserAccount;

			if(shouldRefreshCachedList(context.state.PerLista, refreshCache)) {
				const filter = {};

				if(UserAccount && UserAccount.Roll === 'PER') {
					filter.AnstNr = UserAccount.AnstNr;
				}

				if(context.state.HasWebLon && !context.state.SharedViewFilters.showInactiveEmployeesInEmployeeSelector) {
					filter.Locked = false;
				}

				try {
					context.state.SharedViewFilters.isLoadingPerLista = true;
					context.state.PerLista = await HttpRequester.get('/perlista', {
						params: filter,
					});
					context.state.PerLista.lastFetchedTime = new Date();
				} catch {} finally {
					context.state.SharedViewFilters.isLoadingPerLista = false;
				}

				setInitialSelectedEmployee(context);
			} else if(context.state.PerLista.length === 0 && router.currentRoute && router.currentRoute.meta.RequireAtleastOneEmployeeInRegistry) {
				context.state.PerLista.lastFetchedTime = null;

				setSnackbar(context, {
					title:   $t('Validations.PerRegIsEmptyTitle'),
					text:    $t('Validations.PerRegIsEmptyText'),
					timeout: 15000,
				}, this.dispatch);

				router.push({
					name: 'Dashboard',
				});
			}

			if(UserAccount && UserAccount.Roll === 'PER' && context.state.PerLista.length === 0) {
				this.dispatch('logout');
				setSnackbar(context, {
					title:   '',
					text:    $t('Validations.EmployeePerRegMissing'),
					timeout: 15000,
				}, this.dispatch);
			}
		},

		async loadAvdelningLista(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.AvdelningLista, refreshCache)) {
				context.state.AvdelningLista = await HttpRequester.get('/avdelning');
				context.state.AvdelningLista.lastFetchedTime = new Date();
			}
		},

		async loadOBRegler(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.OBRegler, refreshCache)) {
				context.state.OBRegler = context.state.HasPlus ? await HttpRequester.get('/obregler', {
					params: {
						fields: ['OBRegelID', 'BenText'],
					},
				}) : [];
				context.state.OBRegler.lastFetchedTime = new Date();
			}
		},

		async loadEgenskaper(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.Egenskaper, refreshCache)) {
				context.state.Egenskaper = context.state.HasPlus ? await HttpRequester.get('/egenskaper') : [];
				context.state.Egenskaper.lastFetchedTime = new Date();
			}
		},

		async loadResEnheter(context, {refreshCache = false} = {}) {
			if(shouldRefreshCachedList(context.state.ResultatEnheter, refreshCache)) {
				if(
					context.state.FtgInfo &&
					!context.state.FtgInfo.OpenResEnhet1 &&
					!context.state.FtgInfo.OpenResEnhet2 &&
					!context.state.FtgInfo.OpenResEnhet3 &&
					!context.state.FtgInfo.OpenResEnhet4 &&
					!context.state.FtgInfo.OpenResEnhet5 &&
					!context.state.FtgInfo.OpenResEnhet6
				) {
					context.state.ResultatEnheter[1] = [];
					context.state.ResultatEnheter[2] = [];
					context.state.ResultatEnheter[3] = [];
					context.state.ResultatEnheter[4] = [];
					context.state.ResultatEnheter[5] = [];
					context.state.ResultatEnheter[6] = [];
					
					return;
				}

				const {ResEnh1, ResEnh2, ResEnh3, ResEnh4, ResEnh5, ResEnh6} = await HttpRequester.get('/resenheter');

				context.state.ResultatEnheter[1] = ResEnh1;
				context.state.ResultatEnheter[2] = ResEnh2;
				context.state.ResultatEnheter[3] = ResEnh3;
				context.state.ResultatEnheter[4] = ResEnh4;
				context.state.ResultatEnheter[5] = ResEnh5;
				context.state.ResultatEnheter[6] = ResEnh6;
				context.state.ResultatEnheter.lastFetchedTime = new Date();
			}
		},

		async loadHolidays(context, {refreshCache = false, Year = context.state.SalaryYear} = {}) {
			const YearInt = parseInt(Year, 10);

			if(Year && (!context.state.HolidayYearsLoaded.includes(YearInt) || isEmpty(context.state.Holidays) || refreshCache === true)) {
				context.state.Holidays = {...context.state.Holidays, ...keyBy(await HttpRequester.get(`/holidays/${YearInt}`), 'Datum')}
				context.state.HolidayYearsLoaded.push(YearInt);

				return true;
			}

			return false;
		},

		addHolidays(context, holidays) {
			context.state.Holidays = {...context.state.Holidays, ...keyBy(holidays, 'Datum')};
		},

		async checkForNewDocuments(context) {
			try {
				const {HasPlus, PortalInfo: {EnableDocumentsView}} = context.state;

				if(!HasPlus || !EnableDocumentsView) {
					return;
				}

				const documents = await HttpRequester.get('/documents', {
					params: {
						Seen:   false,
						fields: ['DocumentId'],
					},
				});

				if(documents.length > 0) {
					this.dispatch('setNavigationNotificationCount', {routeName: 'Documents', value: documents.length});
				} else {
					this.dispatch('clearNavigationNotificationCount', {routeName: 'Documents'});
				}
			} catch {}
		},

		async checkForActiveAbsenceReport(context) {
			try {
				const {UserAccount: {AnstNr}, HasLicensOption: {HRTIL}, PortalInfo: {EnableAbsenceReports}} = context.state;

				if(!AnstNr || !HRTIL || !EnableAbsenceReports) {
					return;
				}

				const [AbsenceReport] = await HttpRequester.get('/absenceReports', {
					params: {
						AnstNr,
						Recovered: false,
						fields:    ['Id'],
					},
				});

				if(AbsenceReport) {
					this.dispatch('setNavigationNotificationCount', {routeName: 'AbsenceReports', value: 1});
				} else {
					this.dispatch('clearNavigationNotificationCount', {routeName: 'AbsenceReports'});
				}
			} catch {}
		},

		async checkForActiveNearAccidentReports(context) {
			try {
				const {PortalInfo: {EnableNearAccidentsReports, NearAccidentsReportsAdmins = []}, UserAccount} = context.state;

				if(!EnableNearAccidentsReports || !NearAccidentsReportsAdmins.includes(UserAccount?.Login)) {
					return;
				}

				const items = await HttpRequester.get('/hr/nearAccidentsReports', {
					params: {
						Status: 'NEW',
						fields: ['Id'],
					},
				});

				if(items.length > 0) {
					this.dispatch('setNavigationNotificationCount', {routeName: 'NearAccidentsReports', value: items.length});
				} else {
					this.dispatch('clearNavigationNotificationCount', {routeName: 'NearAccidentsReports'});
				}
			} catch {}
		},

		async checkForLeaveApplications(context) {
			try {
				const {HasPlus, PortalInfo: {EnableLeaveApplications}, UserAccount} = context.state;

				if(!HasPlus || !EnableLeaveApplications || UserAccount?.Roll === 'PER') {
					return;
				}

				const dateFilter = {
					From: moment().startOf('month').format('YYYY-MM-DD'),
					To:   moment().add(1, 'year').startOf('month').subtract(1, 'day')
						.format('YYYY-MM-DD'),
				};

				const pendingLeaveApplications = await HttpRequester.get('/leaveapplications/admin', {
					params: {
						Approved: false,
						Denied:   false,
						FromDate: `${dateFilter.From}|${dateFilter.To}`,
						fields:   ['Id'],
					},
				});

				if(pendingLeaveApplications.length > 0) {
					this.dispatch('setNavigationNotificationCount', {routeName: 'LeaveApplications', value: pendingLeaveApplications.length});
				} else {
					this.dispatch('clearNavigationNotificationCount', {routeName: 'LeaveApplications'});
				}
			} catch {}
		},

		setNavigationNotificationCount(context, {routeName, value}) {
			context.state.NavigationNotificationCount = {...context.state.NavigationNotificationCount, [routeName]: value};
		},

		clearNavigationNotificationCount(context, {routeName}) {
			context.state.NavigationNotificationCount = {...context.state.NavigationNotificationCount, [routeName]: 0};
		},

		confirmDialog(context, options = {}) {
			options.persistent          = options.persistent === true;
			options.autoFocusConfirm    = options.autoFocusConfirm === true;
			context.state.ConfirmDialog = {...options, visible: true};
		},

		hideConfirmDialog(context) {
			context.state.ConfirmDialog.visible = false;
		},

		setupWebSocketClient(context) {
			if(!context.state.WebSocketClient) {
				context.state.WebSocketClient = new WebSocketClient();
			}
		},

		async loadPortalInfo(context, {checkRouterAccess = false} = {}) {
			context.state.PortalInfo = await HttpRequester.get('/portalInfo');

			if(context.state.PortalInfo.EnableMeddelanden) {
				this.dispatch('loadMessagesStatus');
			}

			this.dispatch('checkForNewDocuments');
			this.dispatch('checkForActiveAbsenceReport');
			this.dispatch('checkForActiveNearAccidentReports');
			this.dispatch('checkForLeaveApplications');

			if(checkRouterAccess) {
				router.checkAccess(router.currentRoute, {autoNavigate: true});
			}
		},

		async loadMessagesStatus(context) {
			if(context.state.HasPlus) {
				const {AntalOlast} = await HttpRequester.get('/messages/status');

				context.state.UnreadMessagesCount = AntalOlast;
			}
		},

		async updateUserAppSettings(context, data = {}) {
			Object.assign(context.state.UserAppSettings, data);

			try {
				await HttpRequester.put('/userAppSettings', context.state.UserAppSettings);
			} catch{}
		},

		async loadSchemaInformationForSelectedEmployee(context, {refreshCache = false} = {}) {
			if(!context.state.SharedViewFilters.selectedEmployee) {
				return;
			}

			try {
				if(refreshCache || shouldRefreshCachedList(context.state.SharedViewFilters.selectedEmployee.PerSchema)) {
					if(context.state.FtgInfo.UsePerSchema) {
						context.state.SharedViewFilters.selectedEmployee.PerSchema = sortBy(await HttpRequester.get(`/perschema/${encodeURIComponent(context.state.SharedViewFilters.selectedEmployee.AnstNr)}`), 'FromDatum').reverse();
					} else {
						const {UseArbSchema, ArbSchema} = await HttpRequester.get(`/perreg/${encodeURIComponent(context.state.SharedViewFilters.selectedEmployee.AnstNr)}`, {
							params: {
								fields: ['UseArbSchema', 'ArbSchema'],
							},
						});

						context.state.SharedViewFilters.selectedEmployee.PerSchema = [{
							FromDatum: '1899-12-30',
							UseArbSchema,
							ArbSchema,
						}];
					}

					context.state.SharedViewFilters.selectedEmployee.PerSchema.lastFetchedTime = new Date();
				}
			} catch{}
		},

		addUnreadMessagesCount(context, Amount) {
			context.state.UnreadMessagesCount += Amount;
		},

		updatePerListaProfilePictureId(context, {AnstNr, ProfilePictureId}) {
			const item = context.state.PerLista.find((item) => item.AnstNr === AnstNr);

			if(item) {
				item.ProfilePictureId = ProfilePictureId;
			}
		},

		async handleResourceUpdatedEvent(context, {FtgId, resource, AnstNrList, AnstNr}) {
			try {
				if(FtgId && FtgId !== context.state.FtgInfo.FtgId) {
					return;
				}

				switch(resource) {
					case 'FtgInfo': {
						const newFtgInfo = await HttpRequester.get(`/companyInfo/${encodeURIComponent(FtgId)}`);

						context.dispatch('setUpdatedFtgInfo', {
							checkRouterAccess: true,
							...newFtgInfo,
						});
						break;
					}
					case 'PortalInfo':
						context.dispatch('loadPortalInfo', {refreshCache: true, checkRouterAccess: true});
						break;
					case 'WebPerReg':
					case 'PerStatus':
					case 'PerProfilePicture':
						if(!AnstNrList && AnstNr) {
							AnstNrList = [AnstNr];
						}
						for(const AnstNr of AnstNrList) {
							const match = context.state.PerLista.find((item) => item.AnstNr === AnstNr);

							const [updatedEmployee] = await HttpRequester.get('/perlista', {
								params: {
									AnstNr,
								},
							});

							if(!updatedEmployee && match) {
								//Borttagen
								context.state.PerLista = context.state.PerLista.filter((item) => item.AnstNr !== AnstNr);

								if(context.state.SharedViewFilters.selectedEmployee && context.state.SharedViewFilters.selectedEmployee.AnstNr === AnstNr) {
									context.state.SharedViewFilters.selectedEmployee = context.state.PerLista[0] || null;
								}

								continue;
							}

							if(match) {
								Object.assign(match, updatedEmployee);
								if(context.state.SharedViewFilters.selectedEmployee && context.state.SharedViewFilters.selectedEmployee.AnstNr === AnstNr) {
									this.dispatch('loadSchemaInformationForSelectedEmployee', {refreshCache: true});
								} else if(match.PerSchema) {
									match.PerSchema.lastFetchedTime = null;
								}
							} else {
								updatedEmployee.PerSchema = [];
								updatedEmployee.PerSchema.lastFetchedTime = null;
								context.state.PerLista.push(updatedEmployee);
							}
						}
						break;
					case 'PerSchema': {
						if(context.state.SharedViewFilters.selectedEmployee && context.state.SharedViewFilters.selectedEmployee.AnstNr === AnstNr) {
							this.dispatch('loadSchemaInformationForSelectedEmployee', {refreshCache: true});
						} else {
							const match = context.state.PerLista.find((item) => item.AnstNr === AnstNr);

							if(match && match.PerSchema) {
								match.PerSchema.lastFetchedTime = null;
							}
						}
						break;
					}
					case 'BrukarReg': {
						if(context.state.BrukarLista.lastFetchedTime) {
							this.dispatch('loadBrukarLista', {refreshCache: true});
						}
						break;
					}
					case 'ResEnh1':
					case 'ResEnh2':
					case 'ResEnh3':
					case 'ResEnh4':
					case 'ResEnh5':
					case 'ResEnh6': {
						if(context.state.ResultatEnheter.lastFetchedTime) {
							this.dispatch('loadResEnheter', {refreshCache: true});
						}
						break;
					}
					case 'TidKodGrupp':
					case 'TidKoder': {
						if(context.state.TidKoder.lastFetchedTime) {
							this.dispatch('loadTidKoder', {refreshCache: true});
						}
						break;
					}
					case 'LonKodGrupp':
					case 'LonKoder': {
						if(context.state.LonKoder.lastFetchedTime) {
							this.dispatch('loadLonKoder', {refreshCache: true});
						}
						break;
					}
					case 'Documents': {
						this.dispatch('checkForNewDocuments');
						break;
					}
					case 'NearAccidentsReports': {
						this.dispatch('checkForActiveNearAccidentReports');
						break;
					}
				}
			} catch {}
		},

		async handleAnstNrsChanged(context, {FtgId, Changes = []}) {
			if(context.state.FtgInfo && context.state.FtgInfo.FtgId === FtgId) {
				for(const item of Changes) {
					const {OldAnstNr, NewAnstNr} = item;

					if(context.state.UserAccount && context.state.UserAccount.AnstNr === OldAnstNr) {
						try {
							await HttpRequester.patch('/me/updateSessionUserAccount');

							context.state.UserAccount.AnstNr = NewAnstNr;
						} catch{}
					}

					const perListaItem = (context.state.PerLista || []).find(({AnstNr}) => AnstNr === OldAnstNr);

					if(perListaItem) {
						perListaItem.AnstNr = NewAnstNr;
					}
				}
			}
		},

		async handleBrukarNrsChanged(context, {FtgId, Changes = []}) {
			if(context.state.FtgInfo && context.state.FtgInfo.FtgId === FtgId) {
				for(const item of Changes) {
					const {OldBrukare, NewBrukare} = item;

					if(context.state.UserAccount && context.state.UserAccount.Brukare === OldBrukare) {
						try {
							await HttpRequester.patch('/me/updateSessionUserAccount');

							context.state.UserAccount.Brukare = NewBrukare;
						} catch{}
					}

					const brukarListaItem = (context.state.BrukarLista || []).find(({Brukare}) => Brukare === OldBrukare);

					if(brukarListaItem) {
						brukarListaItem.Brukare = NewBrukare;
					}
				}
			}
		},
	},

	getters: {
		HasLoadedAuthNecessities: (state) => state.Auth.method !== '' && !isEmpty(state.UserAccount) && !isEmpty(state.PortalInfo) && (state.UserAccount.Roll !== 'PER' || state.SharedViewFilters.selectedEmployee),
	},
});

const cacheTime = 15; //Minutes

function shouldRefreshCachedList(list, refreshCache = false) {
	return refreshCache === true ||
		list.length === 0 ||
		!list.lastFetchedTime ||
		((Date.now() - list.lastFetchedTime) / 1000 / 60) > cacheTime
}

function clearContextCache(context, {clearSelectedEmployee = false} = {}) {
	const storeKeys = keys(context.state);

	for(const key of storeKeys) {
		if(isPlainObject(context.state[key]) && find(context.state[key], (value) => value && value.lastFetchedTime)) {
			context.state[key] = {};
		} else if(context.state[key] && context.state[key].lastFetchedTime) {
			context.state[key].lastFetchedTime = null;
		}
	}

	if(clearSelectedEmployee) {
		context.state.SharedViewFilters.selectedEmployee = null;
	}
}

const setInitialSelectedEmployee = (context) => {
	if(!context.state.SharedViewFilters.selectedEmployee && context.state.PerLista.length > 0) {
		context.state.SharedViewFilters.selectedEmployee = context.state.UserAccount.AnstNr ? find(context.state.PerLista, {AnstNr: context.state.UserAccount.AnstNr}) : context.state.PerLista[0];

		context.dispatch('loadSchemaInformationForSelectedEmployee');
	}
};
