/**
 * Created by simon on 2018-12-17.
 */

import {noop, isObject} from 'lodash';

export default async (task, errorHandler = noop, finallyHandler = noop) => {
	if(isObject(task)) {
		errorHandler   = task.catch || task.error || errorHandler;
		finallyHandler = task.finally || finallyHandler;
		task           = task.task || task;
	}

	let result = null;

	try {
		result = await task();
	} catch(error) {
		await errorHandler(error);
	} finally {
		await finallyHandler(result);
	}
};
