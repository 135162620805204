<template>
	<div>
		<v-hover>
			<v-navigation-drawer
				v-model="drawer"
				:clipped="useClippedNavLayout"
				app
				slot-scope="{ hover }"
				:mini-variant="isMini && !hover"
				:width="210"
				:mini-variant-width="60"
				:permanent="$vuetify.breakpoint.lgAndUp"
			>
					<v-list dense class="mt-0 pt-0" :class="{'is-expanded': !isMini || hover}">
						<v-list-item style="min-height: 53px;" class="mt-0 pl-0" @click="canSelectCompany ? onClickSelectCompany() : undefined" :inactive="!canSelectCompany" :ripple="canSelectCompany">
							<v-list-item-avatar class="pa-0 mt-0 mb-0 ml-2" tile :class="{'ml-5': isMini && !hover, 'pl-3': isMini && !hover}">
								<v-icon :color="navIconColor">mdi-domain</v-icon>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title>{{FtgInfo.Namn}}</v-list-item-title>
								<v-list-item-subtitle>{{$formatter.formatPersNrOrgNrTo12(FtgInfo.Orgnr, {dash: true, serialNr: false}).slice(2)}}</v-list-item-subtitle>
							</v-list-item-content>

						</v-list-item>
						<v-divider></v-divider>

						<template v-for="item in navigationItems">
							<v-divider v-if="item.divideBefore"></v-divider>

							<v-list-group
								v-if="item.children"
								:key="item.text"
								:append-icon="''"
								:class="{'listgroupmini': isMini && !hover}"
								:color="'inherit'"
							>
								<template slot="activator">
									<v-list-item class="mt-0 pl-0 pr-0">
										<v-list-item-avatar tile class="pa-0 mt-0 mb-0 ml-2" :class="{'ml-5': isMini && !hover}">
											<v-icon v-if="item.icon" :color="navIconColor">{{item.icon}}</v-icon>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title :to="item.to" v-show="!isMini || hover">
												{{ item.text }}
											</v-list-item-title>

										</v-list-item-content>
										<v-list-item-action v-if="item.notificationCount > 0"><NavigationNotificationCountBadge :count="item.notificationCount"/></v-list-item-action>
									</v-list-item>

								</template>
								<template v-for="(child) in item.children">
									<template v-if="child.rightsCheck ? child.rightsCheck() === true : true">
										<v-divider v-if="child.divideBefore && (!isMini || hover)"></v-divider>
										<v-list-item
											class="reduced-min-height"
											v-show="!isMini || hover"
											:to="child.to"
										>
											<v-list-item-action>
												<v-icon v-if="child.icon">{{ child.icon }}</v-icon>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>
													{{ child.text }}
												</v-list-item-title>
											</v-list-item-content>
											<v-list-item-action v-if="child.notificationCount > 0"><NavigationNotificationCountBadge :count="child.notificationCount"/></v-list-item-action>
										</v-list-item>
										<v-divider v-if="child.divideAfter && (!isMini || hover)"></v-divider>
									</template>
								</template>
							</v-list-group>

							<v-list-item  class="mt-0 pl-0 pr-0" v-if="(!item.children || item.children.length === 0) && (item.rightsCheck ? item.rightsCheck() === true : true)" :key="item.text" :to="item.to">
								<v-list-item-avatar class="pa-0 mt-0 mb-0 ml-2" tile :class="{'ml-5': isMini && !hover}">
									<v-icon v-if="item.icon" :color="navIconColor">{{item.icon}}</v-icon>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title v-show="!isMini || hover">
										{{ item.text }}
									</v-list-item-title>
								</v-list-item-content>
								<v-list-item-action v-if="item.notificationCount > 0"><NavigationNotificationCountBadge :count="item.notificationCount"/></v-list-item-action>
							</v-list-item>

						</template>
					</v-list>

					<template v-slot:append v-if="legacyPortalEnabled">
						<LegacyPortalDialog ref="legacyPortalDialog"/>
						<v-btn text class="ml-0 pl-2" @click="onClickLegacyPortal">
							<v-img
								:src="require('@/assets/img/legacy-portal.png')"
								:lazy-src="require('@/assets/img/legacy-portal.png')"
								:max-width="30"
								contain
								class="legacy-portal mr-2"
								:class="{'ml-1': isMini}"
							/>
							{{ (isMini && !hover) ? '' : $t('OldPortal') }}</v-btn>
					</template>
			</v-navigation-drawer>
		</v-hover>

		<SelectCompanyDialog ref="selectCompanyDialog" @cancel="$refs.selectCompanyDialog.hide()" @change="$refs.selectCompanyDialog.hide()" :showOnMounted="false"/>
	</div>
</template>

<script>
import {mapState}                       from 'vuex';
import {keyBy, isEmpty, includes}       from 'lodash';
import LocalStorage                     from '../lib/LocalStorage.js';
import Tooltip                          from './Tooltip.vue';
import LegacyPortalDialog               from './LegacyPortalDialog.vue';
import SelectCompanyDialog              from './SelectCompanyDialog.vue';
import NavigationNotificationCountBadge from './NavigationNotificationCountBadge.vue';

export default {
	name:       'AppNavigation',
	components: {NavigationNotificationCountBadge, SelectCompanyDialog, LegacyPortalDialog, Tooltip},
	watch:      {
		mini() {
			LocalStorage.set('AppNavigationMini', this.mini);
		},

		drawer() {
			LocalStorage.set('AppNavigationDrawer', this.drawer);
		},

		PortalInfo() {
			this.setNavigationItems();
		},

		FtgInfo() {
			this.setNavigationItems();
		},

		NavigationNotificationCount: {
			deep: true,
			handler() {
				this.setNavigationItems();
			},
		},
	},

	mounted() {
		this.setNavigationItems();

		this.$eventBus.$on('languageSelected', () => {
			setTimeout(() => {
				this.setNavigationItems();
			}, 500);
		});
	},

	methods: {
		onClickSelectCompany() {
			this.$refs.selectCompanyDialog.show();
		},

		onClickLegacyPortal() {
			this.$refs.legacyPortalDialog.onClickLegacyPortal();
		},

		async setNavigationItems() {
			const {options: {routes}} = this.$router;
			const routesKeyedByname = keyBy(routes, 'name');

			if(isEmpty(this.FtgInfo) || isEmpty(this.PortalInfo) || isEmpty(this.UserAccount)) {
				this.navigationItems = [];

				return;
			}

			const PayslipsDisabled = !includes(['AVD', 'PER', 'ADM', 'FTG', 'LIC'], this.UserAccount.Roll);
			const LonebeskedSaldonTitleParts = [];

			if(this.PortalInfo.EnableLonebeskedView && !PayslipsDisabled) {
				LonebeskedSaldonTitleParts.push(this.$t('Navigation.Payslips'));
			}

			if(this.PortalInfo.EnableSaldonView) {
				LonebeskedSaldonTitleParts.push(this.$t('Navigation.Balance'));
			}

			const {HRTIL: UserHRTIL} = this.HasUserOption;
			const {HRTIL: LicHRTIL}  = this.HasLicensOption;

			let LeaveApplicationsNavigation = {
				text: this.UserAccount.Roll === 'PER' ? this.$t('Navigation.LeaveApplicationsEmployee') : this.$t('Navigation.LeaveApplicationsAdmin'),
				icon: 'mdi-white-balance-sunny',
				to:   'LeaveApplications',
			};

			if(LicHRTIL && (UserHRTIL || this.PortalInfo.EnableAbsenceCalendar)) { //Sub-menu only if neccessary
				LeaveApplicationsNavigation = {
					text:     this.$t('Ledigheter'),
					icon:     'mdi-white-balance-sunny',
					to:       'LeaveApplications',
					children: [
						{text: this.$t('Ansökningar'), to: 'LeaveApplications'},
						UserHRTIL ? {text: this.$t('Planering'), to: 'LeavePlannerOverview'} : null,
						{text: this.$t('AbsenceCalendar'), to: 'AbsenceCalendar'},
					].filter((v) => v),
				};
			}

			const baseRoutes = [
				{
					text: this.$t('Navigation.Dashboard'),
					icon: 'mdi-chart-arc',
					to:   'Dashboard',
				},
				{
					text: this.$t('Navigation.PortalFeed'),
					icon: 'mdi-bullhorn',
					to:   'PortalFeedAdmin',
				},
				{
					text: this.$t('Navigation.Kalendarie'),
					icon: 'mdi-calendar-month',
					to:   'Kalendarie',
				},
				{
					text: this.$t('Navigation.Tidrapport'),
					icon: 'mdi-clock-outline',
					to:   'Tidrapport',
				},
				LicHRTIL ? {
					text: this.$t('Navigation.AbsenceReports'),
					icon: 'mdi-emoticon-sick-outline',
					to:   'AbsenceReports',
				} : null,
				{
					text: this.$t('Navigation.Stamplingar'),
					icon: 'mdi-swap-horizontal',
					to:   'Stamplingar',
				},
				{
					text: this.$t('Navigation.ApproveTime'),
					icon: 'mdi-clock-check-outline',
					to:   'ApproveTime',
				},
				{
					text: this.$t('Navigation.FK3059'),
					icon: 'mdi-clipboard-check-outline',
					to:   'FK3059Assistent',
				},
				{
					text: this.$t('Navigation.Schedule'),
					icon: 'mdi-calendar-clock',
					to:   'Schedule',
				},
				{
					text:     this.$t('Navigation.Bemanna'),
					icon:     'mdi-calendar-clock',
					children: [
						{text: this.$t('Översikt'), to: 'BemOverview'},
						{text: this.$t('Navigation.BemBehov'), to: 'BemBehov'},
						{text: this.$t('Navigation.BemPerson'), to: 'BemPlanner'},
						{text: this.$t('Bemanna.Passbyten.Title'), to: 'BemTrade'},
					],
					to: 'Bemanna',
				},
				LeaveApplicationsNavigation,
				{
					text: this.$t('Navigation.Attest'),
					icon: 'mdi-check-outline',
					to:   'Attestera',
				},
				{
					text: this.$t('Navigation.FK3059'),
					icon: 'mdi-clipboard-check-outline',
					to:   'FK3059Anordnare',
				},
				{
					text: this.$t('Navigation.Compensations'),
					icon: 'mdi-credit-card-outline',
					to:   'Compensations',
				},
				{
					text: this.$t('Navigation.TravelExpenses'),
					icon: 'mdi-airplane',
					to:   'TravelExpenses',
				},
				{
					text: LonebeskedSaldonTitleParts.join(' & '),
					icon: 'mdi-sack',
					to:   'LonebeskedSaldon',
				},
				{
					text: this.$t('Navigation.Documents'),
					icon: 'mdi-file-document-outline',
					to:   'Documents',
				},
				{
					text: this.$t('Navigation.PresenceStatus'),
					icon: 'mdi-account-search',
					to:   'PresenceStatus',
				},
				{
					text:         this.$t('Navigation.Personuppgifter'),
					icon:         'mdi-card-account-details-outline',
					to:           'Personuppgifter',
					divideBefore: true,
				},
				{
					text:     this.$t('Rapporter'),
					icon:     'mdi-note-text',
					image:    'crona-lon-ikon-rapporter.svg',
					children: [
						{text: this.$t('Tidrapport'), to: 'TidrapportPrint'},
						{text: this.$t('Ersättningar'), to: 'ErsattningarPrint'},
						{text: this.$t('Attestlista'), to: 'AttestlistaPrint'},
						{text: this.$t('Projektlista'), to: 'ProjektlistaPrint'},
						{text: this.$t('Navigation.TravelExpenses'), to: 'ReserakningPrint'},
					],
				},
				{
					text: this.$t('NearAccidentsReports.Navigation'),
					icon: 'mdi-air-horn',
					to:   'NearAccidentsReports',
				},
				{
					text:     this.$t('Navigation.Administer.Headline'),
					icon:     'mdi-cog-outline',
					children: [
						{text: this.$t('Navigation.Administer.PortalSettings'), to: 'AdminPortalInfo'},
						{text: this.$t('Navigation.Administer.Users'), to: 'AdminUsers'},
						{text: this.$t('Navigation.Administer.Reminders'), to: 'AdminReminders'},
					],
				},
			].filter((v) => v);

			const result = [];

			//All items here should have 'To' as named in router config.
			//They are then changed to 'To' with full path so that selected-nav-item is highlighted.
			for(const item of baseRoutes) {
				if(item.children) {
					const children = [];

					for(const child of item.children) {
						if(await this.$router.checkAccess(child.to)) {
							child.notificationCount = this.NavigationNotificationCount[child.to] || 0;
							if(routesKeyedByname[child.to]) {
								child.to = routesKeyedByname[child.to].path;
							}
							children.push(child);
						}
					}
					item.children = children;
				}

				if(item.to) {
					item.notificationCount = this.NavigationNotificationCount[item.to] || 0;
					if(!await this.$router.checkAccess(item.to)) {
						continue;
					}

					if(routesKeyedByname[item.to]) {
						item.to = routesKeyedByname[item.to].path;
					}
				}

				if(!item.children || item.children.length > 0) {
					result.push(item);
				}
			}

			this.navigationItems = result;
		},
	},

	data() {
		return {
			drawer:              LocalStorage.get('AppNavigationDrawer', true),
			mini:                LocalStorage.get('AppNavigationMini', this.$vuetify.breakpoint.lgAndUp),
			useClippedNavLayout: true,
			navIconColor:        '',
			navigationItems:     [],
		};
	},

	created() {
		this.$eventBus.$on('toggleNavigation', () => {
			if(this.$vuetify.breakpoint.lgAndUp) {
				this.mini = !this.mini;
			} else {
				this.drawer = !this.drawer
			}
		});
	},

	computed: {
		...mapState(['FtgInfo', 'HasPlus', 'UserAccount', 'PortalInfo', 'HasLicensOption', 'NavigationNotificationCount']),

		canSelectCompany() {
			return this.UserAccount.Roll === 'LIC' ||
				this.UserAccount.Roll === 'ADM' ||
				this.UserAccount.FtgIdAccessList.length > 1;
		},

		isMini() {
			return this.mini && this.$vuetify.breakpoint.lgAndUp;
		},
	},
}
</script>

<style scoped>
	::v-deep(.v-list__tile) {
		padding-left: 5px;
		padding-right: 5px;
	}

	/*Removes borders around images/icons when in mini mode*/
	::v-deep(.listgroupmini:after, .listgroupmini:before) {
		content: none;
	}

	::v-deep(.v-list-group__header) {
		padding: 0 !important;
	}

	::v-deep(.is-expanded .v-avatar) {
		margin-right: 8px !important;
	}

	::v-deep(.is-expanded .v-list-group__items .v-list-item) {
		padding: 0 !important;
	}

	::v-deep(.reduced-min-height) {
		min-height: 20px;
	}

	::v-deep(.theme--dark .v-image__image) {
		filter: brightness(110%);
	}
</style>
